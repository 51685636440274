import {
  createOrder,
  formatOrders,
  getOrders,
  cancelOrder,
  updateOrder,
  updateOrderStatus,
  isUserExist,
} from "../../services/OrdersService";
import {
  CONFIRMED_CREATE_ORDER_ACTION,
  CONFIRMED_CANCEL_ORDER_ACTION,
  CONFIRMED_EDIT_ORDER_ACTION,
  CONFIRMED_GET_ORDERS,
  CONFIRMED_UPDATE_ORDER_STATUS_ACTION,
  CONFIRMED_USER_EXIST_ACTION,
} from "./OrderTypes";
import swal from "sweetalert";

export function cancelOrderAction(order, history) {
  return async (dispatch, getState) => {
    try {
      let response = await cancelOrder(order);
      console.log("order", order);
      dispatch(confirmedCancelOrderAction(order));
      if (history) {
        history.push("/orderpage");
      }
      return response.data && response.data?.message
        ? response.data.message
        : "Order cancelled successfully";
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to cancel order", "error");
      return false;
    }
  };
}

export function confirmedCancelOrderAction(order) {
  return {
    type: CONFIRMED_CANCEL_ORDER_ACTION,
    payload: order,
  };
}

export function createOrderAction(orderData, history) {
  return async (dispatch, getState) => {
    try {
      let response = await createOrder(orderData);
      if (response) {
        const singleOrder = {
          // bannerData: bannerData,
          // imageUrl: response.data.imageUrl,
          // title: response.data.title,
        };
        dispatch(confirmedCreateOrderAction(singleOrder));
        // alert("order added successfully");

        if (history) {
          history.push("/order");
        }
        return response.data && response.data?.message
          ? response.data.message
          : "Order created successfully";
      } else {
        // swal("Oops", "Failed to create order", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to create order", "error");
      return false;
    }
  };
}

export function isUserExistAction(mobileNumber) {
  return async (dispatch, getState) => {
    try {
      let response = await isUserExist(mobileNumber);
      if (response) {
        const singleOrder = {
          // bannerData: bannerData,
          // imageUrl: response.data.imageUrl,
          // title: response.data.title,
        };
        dispatch(confirmedCreateOrderAction(mobileNumber));
        // if (history) {
        //   history.push("/Orderpage");
        // }
        // return response.data.success;
        return response.data && response.data?.message
          ? response.data.message
          : "user is exist";
      } else {
        // swal("Oops", "Failed to check user exist", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to check user exist", "error");
      return false;
    }
  };
}
export function updateOrderStatusAction(orderId, newStatus) {
  return async (dispatch, getState) => {
    try {
      let response = await updateOrderStatus(orderId, newStatus);
      if (response) {
        const updatedOrderStatus = {
          status: newStatus,
          updatedAt: response.data.updatedAt,
        };
        dispatch(confirmedUpdateOrderStatusAction(updatedOrderStatus));
        return response.data.success;
      } else {
        // swal("Oops", "Failed to update order status", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to update order status", "error");
      return false;
    }
  };
}
export function confirmedUpdateOrderStatusAction(updatedOrderStatus) {
  return {
    type: CONFIRMED_UPDATE_ORDER_STATUS_ACTION,
    payload: updatedOrderStatus,
  };
}
export function getOrdersAction() {
  return async (dispatch, getState) => {
    try {
      let response = await getOrders();
      console.log("get order responce=====", response);
      if (response) {
        let orders = formatOrders(response.data);
        dispatch(confirmedGetOrdersAction(orders));
      } else {
        // swal("Oops", "Failed to get orders", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to get orders", "error");
      return false;
    }
  };
}

export function confirmedCreateOrderAction(singleOrder) {
  return {
    type: CONFIRMED_CREATE_ORDER_ACTION,
    payload: singleOrder,
  };
}

export function confirmedisUserExistAction(userExist) {
  return {
    type: CONFIRMED_USER_EXIST_ACTION,
    payload: userExist,
  };
}

export function confirmedGetOrdersAction(orders) {
  return {
    type: CONFIRMED_GET_ORDERS,
    payload: orders,
  };
}

export function confirmedUpdateOrderAction(order) {
  return {
    type: CONFIRMED_EDIT_ORDER_ACTION,
    payload: order,
  };
}

export function updateOrderAction(order, history) {
  return async (dispatch, getState) => {
    try {
      let response = await updateOrder(order, order.orderId);
      if (response) {
        dispatch(confirmedUpdateOrderAction(order));
        if (history) {
          history.push("/orderpage");
        }
        return response.data && response.data?.message
          ? response.data?.message
          : "Order updated successfully";
      } else {
        // swal("Oops", "Failed to update orders", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to check update order", "error");
      return false;
    }
  };
}
