import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import MainPagetitle from "../../layouts/MainPagetitle";
import {
  createServiceAction,
  updateServiceAction,
} from "../../../store/actions/ServicesActions";
import { useDispatch } from "react-redux";
import axios from "axios";
import axiosInstance, { baseURL } from "../../../services/AxiosInstance";
import Loader from "../Loader/Loader";
import Swal from "sweetalert2";

export const s5BucketBaseUrl =
  "https://s5-assets-stage.s3.ap-south-1.amazonaws.com";
// Constants for select options
const urlTypeOptions = [
  { value: "external", label: "External" },
  { value: "internal", label: "Internal" },
];

const statusOptions = [
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

const AddService = () => {
  const location = useLocation();
  const selecetdService = location.state?.selectedService || {}; // Check if editing or adding

  const [formData, setFormData] = useState({
    identifier: "",
    brandName: "",
    headline: "",
    bannerUrl: "",
    image: "",
    bodyText: "",
    description: "",
    feature1: "",
    feature2: "",
    feature3: "",
    clientName: "",
    promoCode: "",
    buttonText: "",
    buttonUrl: "",
    buttonUrlType: "external",
    textViewText: "",
    textViewUrl: "",
    textViewUrlType: "internal",
    status: "1",
  });

  const [brandImage, setBrandImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [brandImageURL, setBrandImageURL] = useState(null);
  const [bannerImageURL, setBannerImageURL] = useState(null);
  const [brandImageLoader, setBrandImageLoader] = useState(null);
  const [bannerImageLoader, setBannerImageLoader] = useState(null);

  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //s3 busket upload

  // const [file, setFile] = (useState < File) | (null > null);
  const [isUplaoding, setIsUploading] = useState(false);
  const [loader, setLoader] = useState();

  // If editing, load the selected service data into the form

  useEffect(() => {
    if (loader) {
      document.body.classList.add("blur");
    } else {
      document.body.classList.remove("blur");
    }
  }, [loader]);

  useEffect(() => {
    if (selecetdService && Object.keys(selecetdService).length > 0) {
      setFormData({
        identifier: selecetdService.identifier || selecetdService.identifier,
        brandName: selecetdService.brandName || "",
        headline: selecetdService.headline || "",
        image: selecetdService.image || "",
        bannerUrl: selecetdService.bannerUrl || "",
        bodyText: selecetdService.bodyText || "",
        description: selecetdService.description || "",
        feature1: selecetdService.feature1 || "",
        feature2: selecetdService.feature2 || "",
        feature3: selecetdService.feature3 || "",
        clientName: selecetdService.clientName || "",
        promoCode: selecetdService.promoCode || "",
        buttonText: selecetdService.buttonText || "",
        buttonUrl: selecetdService.buttonUrl || "",
        buttonUrlType:
          selecetdService?.buttonUrlType?.toLowerCase() || "external",
        textViewText: selecetdService.textViewText || "",
        textViewUrl: selecetdService.textViewUrl || "",
        textViewUrlType:
          selecetdService?.textViewUrlType?.toLowerCase() || "internal",
        status: selecetdService.status ? "1" : "0",
      });
    }
  }, [selecetdService]);

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const files = event.target.files;
  //   if (files && files[0]) {
  //     setBrandImage(files[0]);
  //   }
  // };

  const handleUpload = async (file, type) => {
    if (!file) return;

    try {
      //  set isuploading true
      setIsUploading(true);

      // check file size if it is less than 10MB
      // if (file.size < 10000000) {
      // Call your API to get the presigned URL
      const timestamp = new Date().getTime();

      const response = await axios.post(
        `https://api-staging.simpli5.in/upload/generatPresignUrl`,
        {
          fileName: `${type}/image/${timestamp}.png`,
        }
      );

      const url = response.data.presignedUrl;
      console.log("Response from server: ", url);

      // Use the presigned URL to upload the file
      const uploadResponse = await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      console.log("Final Uplaodresponse---------- ", uploadResponse);

      if (uploadResponse.status === 200) {
        // alert("File uploaded successfully.");
        if (type.includes("banner")) {
          let imageUrl = `${s5BucketBaseUrl}/${type}/image/${timestamp}.png`;
          setFormData({
            ...formData,
            bannerUrl: imageUrl,
          });
          // setBannerImageURL(url);
          setBannerImageLoader(false);
        } else {
          let imageUrl = `${s5BucketBaseUrl}/${type}/image/${timestamp}.png`;
          setFormData({
            ...formData,
            image: imageUrl,
          });
          // setBrandImageURL(url);
          setBrandImageLoader(false);
        }
      } else {
        alert("Upload failed.");
      }

      // set isUpload false
      setIsUploading(false);
      // }
    } catch (error) {
      alert("Upload failed.");
    }
  };

  // Handlers for file input changes
  const handleBrandImageUpload = (event) => {
    setBrandImageLoader(true);
    // setBrandImageURL(undefined);
    const files = event.target.files;

    if (files && files[0]) {
      setBrandImage(files[0]);
      handleUpload(files[0], "s5services/logo");
    } else {
      setBrandImage(null);
      setError("Please upload a valid JPG or PNG for Brand Image.");
    }
  };

  const handleBannerImageUpload = (event) => {
    setBannerImageLoader(true);
    // setBannerImageURL(undefined);
    const files = event.target.files;
    if (files && files[0]) {
      setBannerImage(files[0]);
      handleUpload(files[0], "s5services/banner");
    } else {
      setBannerImage(null);
      setError("Please upload a valid JPG or PNG for Brand Image.");
    }
  };

  // URL Validation: Ensure the URL starts with https://
  function isValidUrl(string) {
    if (string == "") {
      return true;
    }
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }

  const validateForm = () => {
    const newErrors = {};
    if (!formData.brandName) {
      newErrors.brandName = "Brand Name is required";
    }
    if (!formData.headline) {
      newErrors.headline = "Headline is required";
    }
    if (!isValidUrl(formData.buttonUrl)) {
      newErrors.buttonUrl = "Please enter valid url";
    }
    if (!isValidUrl(formData.textViewUrl)) {
      newErrors.textViewUrl = "Please enter valid url";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    // formData.bannerUrl = `${s5BucketBaseUrl}/s5services/banner/image.png`;
    // formData.image = `${s5BucketBaseUrl}/s5services/logo/image.png`;
    console.log("selected brands======", formData);
    console.log("selected serviesss======", selecetdService);

    // formData.identifier = formData.brandName;
    // Perform submit action
    if (selecetdService && Object.keys(selecetdService).length > 0) {
      // Update service logic here
      let result = await dispatch(
        updateServiceAction(formData, selecetdService._id)
      );

      if (result) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `${result}`,
          confirmButtonText: "OK",
        });

        navigate("/services");
      }
    } else {
      // Add new service logic here
      let result = await dispatch(createServiceAction(formData));
      if (result) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `${result}`,
          confirmButtonText: "OK",
        });

        navigate("/services");
      }
    }
    // Navigate back to services list after add or update
  };

  return (
    <>
      <MainPagetitle
        mainTitle="Service"
        pageTitle="Service"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-9 col-lg-8">
            <div className="card profile-card card-bx m-b30">
              <div className="card-header">
                <h6 className="title">
                  {selecetdService.brandName ? "Edit" : "Add"} Service
                </h6>
              </div>
              <form className="profile-form" onSubmit={handleSubmit}>
                <div className="card-body">
                  <div className="row">
                    {/* Brand Name (Required) */}
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">
                        Brand Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.brandName ? "border-danger" : ""
                        }`}
                        value={formData.brandName}
                        onChange={(e) => {
                          let value = e.target.value;
                          if (value) {
                            delete errors.brandName;
                          }
                          setFormData({
                            ...formData,
                            brandName: e.target.value,
                          });
                        }}
                        placeholder="Enter brand name"
                      />
                      {errors.brandName && (
                        <small className="text-danger">
                          {errors.brandName}
                        </small>
                      )}
                    </div>

                    {/* identifier */}
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">Identifier</label>
                      <input
                        type="text"
                        className={`form-control`}
                        value={formData.identifier}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFormData({
                            ...formData,
                            identifier: value,
                          });
                        }}
                        placeholder="Enter Identifier"
                      />
                    </div>

                    {/* Headline (Required) */}
                    <div className="col-sm-12 m-b30">
                      <label className="form-label">
                        Headline <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.headline ? "border-danger" : ""
                        }`}
                        value={formData.headline}
                        onChange={(e) => {
                          let value = e.target.value;
                          if (value) {
                            delete errors.headline;
                          }
                          setFormData({
                            ...formData,
                            headline: e.target.value,
                          });
                        }}
                        placeholder="Enter headline"
                      />
                      {errors.headline && (
                        <small className="text-danger">{errors.headline}</small>
                      )}
                    </div>

                    {/* Brand Image */}
                    <div className="col-sm-4 m-b30">
                      {/* <label className="form-label">Brand Image</label>
                      <input
                        type="file"
                        className="form-control-file"
                        onChange={handleBrandImageUpload}
                      />
                      <label>Brand Image (Upload)</label> */}
                      <input
                        type="file"
                        id="uploadLogo"
                        className="d-none"
                        accept=".png,.jpg,.jpeg"
                        onChange={handleBrandImageUpload}
                      />
                      <button
                        type="button"
                        className="btn btn-info mb-2"
                        onClick={() =>
                          document.getElementById("uploadLogo").click()
                        }
                      >
                        {!formData.image ? "Upload Logo" : "Replace Logo"}
                      </button>
                      {brandImageLoader && <div>Uploading...</div>}

                      {!brandImageLoader && formData.image && (
                        <div>
                          <img
                            src={formData.image}
                            alt="Brand Preview"
                            style={{
                              // width: "200px",
                              height: "200px",
                              marginTop: "10px",
                              aspectRatio: "1/1",
                              // objectFit: "cover",
                            }}
                          />
                        </div>
                      )}
                    </div>

                    {/* Banner Image */}
                    <div className="col-sm-8 m-b30">
                      {/* <label className="form-label">Banner Image</label>
                      <input
                        type="file"
                        className="form-control-file"
                        onChange={handleBannerImageUpload}
                      /> */}

                      <input
                        type="file"
                        id="uploadBanner"
                        className="d-none"
                        accept=".png,.jpg,.jpeg"
                        onChange={handleBannerImageUpload}
                      />
                      <button
                        type="button"
                        className="btn btn-info mb-2"
                        onClick={() =>
                          document.getElementById("uploadBanner").click()
                        }
                      >
                        {!formData.bannerUrl
                          ? "Upload Banner"
                          : "Replace Banner"}
                      </button>

                      {bannerImageLoader && <div>Uploading...</div>}

                      {formData.bannerUrl && !bannerImageLoader && (
                        <div>
                          <img
                            src={formData.bannerUrl}
                            alt="Banner Preview"
                            style={{
                              // width: "500px",
                              height: "200px",
                              marginTop: "10px",
                              aspectRatio: "16/9", // This maintains a 16:9 aspect ratio
                              // objectFit: "cover",
                            }}
                          />
                        </div>
                      )}
                    </div>

                    {/* Body Text */}
                    <div className="col-sm-12 m-b30">
                      <label className="form-label">Body Text</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.bodyText}
                        onChange={(e) =>
                          setFormData({ ...formData, bodyText: e.target.value })
                        }
                        placeholder="Enter body text"
                      />
                    </div>

                    {/* Description */}
                    <div className="col-sm-12 m-b30">
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control"
                        value={formData.description}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            description: e.target.value,
                          })
                        }
                        placeholder="Enter description"
                        rows="4"
                      />
                    </div>

                    {/* Feature 1 */}
                    <div className="col-sm-4 m-b30">
                      <label className="form-label">Feature 1</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.feature1}
                        onChange={(e) =>
                          setFormData({ ...formData, feature1: e.target.value })
                        }
                        placeholder="Enter feature 1"
                      />
                    </div>

                    {/* Feature 2 */}
                    <div className="col-sm-4 m-b30">
                      <label className="form-label">Feature 2</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.feature2}
                        onChange={(e) =>
                          setFormData({ ...formData, feature2: e.target.value })
                        }
                        placeholder="Enter feature 2"
                      />
                    </div>

                    {/* Feature 3 */}
                    <div className="col-sm-4 m-b30">
                      <label className="form-label">Feature 3</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.feature3}
                        onChange={(e) =>
                          setFormData({ ...formData, feature3: e.target.value })
                        }
                        placeholder="Enter feature 3"
                      />
                    </div>

                    {/* Client Name */}
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">Client Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.clientName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            clientName: e.target.value,
                          })
                        }
                        placeholder="Enter client name"
                      />
                    </div>

                    {/* Promo Code */}
                    <div className="col-sm-6 m-b30">
                      <label className="form-label">Promo Code</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.promoCode}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            promoCode: e.target.value,
                          })
                        }
                        placeholder="Enter promo code"
                      />
                    </div>

                    {/* Button Text */}
                    <div className="col-sm-4 m-b30">
                      <label className="form-label">Button Text</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.buttonText ? "border-danger" : ""
                        }`}
                        value={formData.buttonText}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            buttonText: e.target.value,
                          })
                        }
                        placeholder="Enter button text"
                      />
                    </div>

                    {/* Button URL */}
                    <div className="col-sm-4 m-b30">
                      <label className="form-label">Button URL</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.buttonUrl ? "border-danger" : ""
                        }`}
                        value={formData.buttonUrl}
                        onChange={(e) => {
                          let value = e.target.value;
                          if (isValidUrl(value)) {
                            delete errors.buttonUrl;
                          }
                          setFormData({
                            ...formData,
                            buttonUrl: e.target.value,
                          });
                        }}
                        placeholder="Enter button URL"
                      />
                      {errors.buttonUrl && (
                        <small className="text-danger">
                          {errors.buttonUrl}
                        </small>
                      )}
                    </div>

                    {/* Button URL Type */}
                    <div className="col-sm-4 m-b30">
                      <label className="form-label">Button URL Type</label>
                      <Select
                        options={urlTypeOptions}
                        className="custom-react-select"
                        value={urlTypeOptions.find(
                          (option) => option.value === formData.buttonUrlType
                        )}
                        onChange={(selectedOption) =>
                          setFormData({
                            ...formData,
                            buttonUrlType: selectedOption.value,
                          })
                        }
                        isSearchable={false}
                      />
                    </div>

                    {/* TextView Text */}
                    <div className="col-sm-4 m-b30">
                      <label className="form-label">TextView Text</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.textViewText ? "border-danger" : ""
                        }`}
                        value={formData.textViewText}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            textViewText: e.target.value,
                          })
                        }
                        placeholder="Enter text view text"
                      />
                    </div>

                    {/* TextView URL */}
                    <div className="col-sm-4 m-b30">
                      <label className="form-label">TextView URL</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.textViewUrl ? "border-danger" : ""
                        }`}
                        value={formData.textViewUrl}
                        onChange={(e) => {
                          let value = e.target.value;
                          if (isValidUrl(value)) {
                            delete errors.textViewUrl;
                          }
                          setFormData({
                            ...formData,
                            textViewUrl: e.target.value,
                          });
                        }}
                        placeholder="Enter text view URL"
                      />
                      {errors.textViewUrl && (
                        <small className="text-danger">
                          {errors.textViewUrl}
                        </small>
                      )}
                    </div>

                    {/* TextView URL Type */}
                    <div className="col-sm-4 m-b30">
                      <label className="form-label">TextView URL Type</label>
                      <Select
                        options={urlTypeOptions}
                        className="custom-react-select"
                        value={urlTypeOptions.find(
                          (option) => option.value === formData.textViewUrlType
                        )}
                        onChange={(selectedOption) =>
                          setFormData({
                            ...formData,
                            textViewUrlType: selectedOption.value,
                          })
                        }
                        isSearchable={false}
                      />
                    </div>

                    {/* Status */}
                    <div className="col-sm-4 m-b30">
                      <label className="form-label">Status</label>
                      <Select
                        options={statusOptions}
                        className="custom-react-select"
                        value={statusOptions.find(
                          (option) => option.value === formData.status // Match the correct value
                        )}
                        onChange={(selectedOption) =>
                          setFormData({
                            ...formData,
                            status: selectedOption.value, // Set the correct value for status
                          })
                        }
                        isSearchable={false}
                      />
                    </div>
                  </div>

                  {/* Error Message Display */}
                  {error && (
                    <div className="col-12">
                      <p style={{ color: "red" }}>{error}</p>
                    </div>
                  )}
                </div>

                {/* Submit Button */}
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary">
                    {selecetdService?.brandName ? "UPDATE" : "ADD"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddService;
