import React, { useContext } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import "./index.css";
import "./chart.css";
import "./step.css";
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

// Dashboard
import Home from "./components/Dashboard/Home";

//s5 buddy
import Order from "./components/S5buddy/Order";
import OrderDetail from "./components/S5buddy/OrderDetails";
// Services
import Service from "./components/Cms/Service";
import Banners from "./components/Cms/Banners";
import AddService from "./components/Cms/AddService";

//menu user
import AdminUser from "./components/User/AdminUsers";
import AppUser from "./components/User/AppUsers";
import Role from "./components/User/Roles";
import UserDetail from "./components/User/UserDetail";

// Apps
import Contacts from "./components/AppsMenu/Contacts";
import User1 from "./components/AppsMenu/AppProfile/User";
import UserRoles from "./components/AppsMenu/AppProfile/UserRoles";
import AddRole from "./components/AppsMenu/AppProfile/AddRole";
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import EditProfile from "./components/AppsMenu/AppProfile/EditProfile";

// Email
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";

// Personal Details
import Uidetails from "./components/Personal-Details/Basic_Details";
import Uiproperties from "./components/Personal-Details/User_Properties";
import Uiother from "./components/Personal-Details/Other_details";

// Pages
import LockScreen from "./pages/LockScreen";
import Error404 from "./pages/Error404";
import { ThemeContext } from "../context/ThemeContext";

const Markup = () => {
  const allroutes = [
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },
    { url: "banners", component: <Banners /> },
    { url: "services", component: <Service /> },

    { url: "addService", component: <AddService /> },
    { url: "admin-user", component: <AdminUser /> },
    { url: "app-user", component: <AppUser /> },
    { url: "userdetail", component: <UserDetail /> },
    { url: "orderdetail", component: <OrderDetail /> },
    { url: "user-role", component: <Role /> },
    { url: "order", component: <Order /> },
    { url: "contacts", component: <Contacts /> },
    { url: "user1", component: <User1 /> },
    { url: "user-roles", component: <UserRoles /> },
    { url: "add-role", component: <AddRole /> },
    { url: "app-profile", component: <AppProfile /> },
    { url: "edit-profile", component: <EditProfile /> },
    { url: "email-compose", component: <Compose /> },
    { url: "email-inbox", component: <Inbox /> },
    { url: "email-read", component: <Read /> },
    { url: "app-calender", component: <Calendar /> },
    { url: "ui-details", component: <Uidetails /> },
    { url: "ui-properties", component: <Uiproperties /> },
    { url: "ui-other", component: <Uiother /> },
  ];

  function NotFound() {
    const urlPaths = allroutes.map((route) => route.url);
    let path = window.location.pathname.split("/").pop();
    if (!urlPaths.includes(path)) {
      return <Error404 />;
    }
  }

  return (
    <>
      <Routes>
        <Route path="/page-lock-screen" element={<LockScreen />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}

          <Route path="/" element={<Home />} />
          <Route exact path="/services" element={<Service />} />
          <Route exact path="/dashboard" element={<Home />} />

          <Route exact path="/addService" element={<AddService />} />

          <Route exact path="/banners" element={<Banners />} />
        </Route>
        {/* <Route path="/page-error-404" element={<Error404 />} /> */}
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
