import {
  createAppUser,
  formatAppUsers,
  getAppUsers,
  deleteAppUser,
  updateAppUser,
} from "../../services/AppUsersService";
import {
  CONFIRMED_CREATE_APPUSER_ACTION,
  CONFIRMED_DELETE_APPUSER_ACTION,
  CONFIRMED_EDIT_APPUSER_ACTION,
  CONFIRMED_GET_APPUSERS,
} from "./AppUserTypes.js";
import swal from "sweetalert";

export function deleteAppUserAction(appuser, history) {
  return async (dispatch, getState) => {
    try {
      let response = await deleteAppUser(appuser);

      dispatch(confirmedDeleteAppUserAction(appuser));
      if (history) {
        history.push("/appuserpage");
      }
      return response.data && response.data?.message
        ? response.data.message
        : "App user deleted successfully";
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to delete app user", "error");
      return false;
    }
  };
}

export function confirmedDeleteAppUserAction(appuser) {
  return {
    type: CONFIRMED_DELETE_APPUSER_ACTION,
    payload: appuser,
  };
}

export function createAppUserAction(appuserData, history) {
  return (dispatch, getState) => {
    try {
      createAppUser(appuserData).then((response) => {
        if (response) {
          const singleAppUser = {
            // appuserData: appuserData,   //need to add the field of appuser
            // imageUrl: response.data.imageUrl,
            // title: response.data.title,
          };
          dispatch(confirmedCreateAppUserAction(singleAppUser));
          if (history) {
            history.push("/appuserpage");
          }
          return response.data && response.data?.message
            ? response.data.message
            : "App user created successfully";
        } else {
          // swal("Oops", "Failed to create appuser", "error");
          return false;
        }
      });
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to create appuser", "error");
      return false;
    }
  };
}

export function getAppUsersAction() {
  return async (dispatch, getState) => {
    try {
      let response = await getAppUsers();
      console.log("get appuser responce=====", response.data.data);
      if (response) {
        let appusers = formatAppUsers(response.data.data);
        dispatch(confirmedGetAppUsersAction(response.data.data));
        return response.data.message ? response.data.message : "";
      } else {
        // swal("Oops", "Failed to get appusers", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to get appusers", "error");
      return false;
    }
  };
}

export function confirmedCreateAppUserAction(singleAppUser) {
  return {
    type: CONFIRMED_CREATE_APPUSER_ACTION,
    payload: singleAppUser,
  };
}

export function confirmedGetAppUsersAction(appusers) {
  return {
    type: CONFIRMED_GET_APPUSERS,
    payload: appusers,
  };
}

export function confirmedUpdateAppUserAction(appuser) {
  return {
    type: CONFIRMED_EDIT_APPUSER_ACTION,
    payload: appuser,
  };
}

export function updateAppUserAction(appuser, appuserId, history) {
  return (dispatch, getState) => {
    try {
      updateAppUser(appuser, appuserId).then((response) => {
        if (response) {
          dispatch(confirmedUpdateAppUserAction(appuser));
          if (history) {
            history.push("/appuserpage");
          }
          return response.data && response.data?.message
            ? response.data.message
            : "App user updated successfully";
        } else {
          // swal("Oops", "Failed to update appusers", "error");
          return false;
        }
      });
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to update appusers", "error");
      return false;
    }
  };
}
