import {
  createRole,
  formatRoles,
  getRoles,
  deleteRole,
  updateRole,
} from "../../services/RolesService";
import {
  CONFIRMED_CREATE_ROLE_ACTION,
  CONFIRMED_DELETE_ROLE_ACTION,
  CONFIRMED_EDIT_ROLE_ACTION,
  CONFIRMED_GET_ROLES,
} from "./RoleTypes";
import swal from "sweetalert";

export function deleteRoleAction(role, history) {
  return async (dispatch, getState) => {
    try {
      console.log("role ===>", role);
      let response = await deleteRole(role);
      dispatch(confirmedDeleteRoleAction(role));
      if (history) {
        history.push("/rolepage");
      }
      return response.data && response.data?.message
        ? response.data.message
        : "Role deleted successfully";
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to delete role", "error");
      return false;
    }
  };
}

export function confirmedDeleteRoleAction(role) {
  return {
    type: CONFIRMED_DELETE_ROLE_ACTION,
    payload: role,
  };
}

export function createRoleAction(roleData, history) {
  return async (dispatch, getState) => {
    try {
      let response = await createRole(roleData);
      if (response) {
        const singlerole = {
          roleData: roleData,
          modules: response.data.modules,
          roleName: response.data.roleName,
        };
        dispatch(confirmedCreateRoleAction(singlerole));
        if (history) {
          history.push("/rolepage");
        }
        return response.data && response.data?.message
          ? response.data.message
          : "Role created successfully";
      } else {
        // swal("Oops", "Failed to create role", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to create role", "error");
      return false;
    }
  };
}

export function getRolesAction() {
  return async (dispatch, getState) => {
    try {
      let response = await getRoles();
      console.log("get role responce=====", response);
      if (response) {
        let roles = formatRoles(response.data);
        dispatch(confirmedGetRolesAction(roles));
        return roles;
      } else {
        // swal("Oops", "Failed to get roles", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to get roles", "error");
      return false;
    }
  };
}

export function confirmedCreateRoleAction(singleRole) {
  return {
    type: CONFIRMED_CREATE_ROLE_ACTION,
    payload: singleRole,
  };
}

export function confirmedGetRolesAction(roles) {
  return {
    type: CONFIRMED_GET_ROLES,
    payload: roles,
  };
}

export function confirmedUpdateRoleAction(role) {
  return {
    type: CONFIRMED_EDIT_ROLE_ACTION,
    payload: role,
  };
}

export function updateRoleAction(role, roleId, history) {
  return async (dispatch, getState) => {
    try {
      let response = updateRole(role, roleId);
      if (response) {
        dispatch(confirmedUpdateRoleAction(role));
        if (history) {
          history.push("/rolepage");
        }
        return response.data && response.data?.message
          ? response.data.message
          : "Role udpated successfully";
      } else {
        // swal("Oops", "Failed to update roles", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to update roles", "error");
      return false;
    }
  };
}
