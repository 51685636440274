import axiosInstance from "./AxiosInstance";

export function getRoles() {
  let data = {
    roleName: "superAdmin",
    modules: "CMS",
    deleted: 0,
  };
  return axiosInstance.get(`/admin/role`, data);
}

export function createRole(roleData) {
  return axiosInstance.post(`/admin/role`, roleData);
}

export function updateRole(roleData, roleId) {
  return axiosInstance.put(`/admin/role/updateRole/${roleId}`, roleData);
}

export function deleteRole(role) {
  console.log("id=====>", role);
  return axiosInstance.delete(`/admin/role/${role._id}`);
}

export function formatRoles(roleData) {
  let roles = [];
  for (let key in roleData) {
    roles.push({ ...roleData[key], id: key });
  }

  return roles;
}
