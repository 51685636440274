import { SVGICON } from "../../constant/theme";

export const getMenuList = (roles) => {
  let menuList = [];

  menuList = menuList.concat([
    {
      title: "Dashboard",
      classsChange: "mm-collapse",
      iconStyle: SVGICON.Home,
      to: "/dashboard",
      // content: [
      //   {
      //     title: "Dashboard",
      //     to: "/dashboard",
      //   },
      // ],
    },
  ]);

  roles.forEach((role) => {
    switch (role) {
      case "CMS":
        menuList = menuList.concat([
          {
            title: "CMS",
            classsChange: "mm-collapse",
            iconStyle: SVGICON.SettingMenu,
            content: [
              {
                title: "Banners",
                to: "/banners",
              },
              {
                title: "Services",
                to: "/services",
                hasMenu: false,
                // content: [
                //   {
                //     title: "Add Service",
                //     to: "addService",
                //   },
                // ],
              },
            ],
            // {
            //   title: "Add Client",
            //   to: "add",
            // },
          },
        ]);
        break;
      case "S5-Buddy":
        menuList = menuList.concat([
          {
            title: "S5 Buddy",
            classChange: "mm-collapse",
            iconStyle: SVGICON.Plugins,
            content: [
              {
                title: "Orders",
                to: "/order",
                // content: [
                //   {
                //     title: "Add Order",
                //     to: "/orderdetail",
                //   },
                // ],
              },
            ],
          },
        ]);

        break;

      case "AppUsers":
        menuList = menuList.concat([
          {
            title: "Users",
            classsChange: "mm-collapse",
            iconStyle: SVGICON.Apps,
            content: [
              {
                title: "App Users",
                to: "/app-user",
                // content: [
                //   {
                //     title: "User Details",
                //     to: "/userdetail",
                //   },
                // ],
              },
            ],
          },
        ]);
        break;

      case "Users":
        menuList = menuList.concat([
          {
            title: "Users",
            classsChange: "mm-collapse",
            iconStyle: SVGICON.Apps,
            content: [
              {
                title: "Admin Users",
                to: "/admin-user",
              },
              {
                title: "App Users",
                to: "/app-user",
                // content: [
                //   {
                //     title: "User Details",
                //     to: "/userdetail",
                //   },
                // ],
              },

              {
                title: "Roles",
                to: "/user-role",
              },
            ],
          },
        ]);
      default:
        // do nothing
        break;
    }
  });

  return menuList;
};
