import {
  createService,
  formatServices,
  getServices,
  deleteService,
  updateService,
} from "../../services/ServicesService";
import {
  CONFIRMED_CREATE_SERVICES_ACTION,
  CONFIRMED_DELETE_SERVICES_ACTION,
  CONFIRMED_EDIT_SERVICES_ACTION,
  CONFIRMED_GET_SERVICES,
} from "./ServicesTypes";
import swal from "sweetalert";

export function deleteServiceAction(service, history) {
  return async (dispatch, getState) => {
    try {
      const response = await deleteService(service);
      dispatch(confirmedDeleteServiceAction(service));
      // if (history) {
      //   history.push("/servicepage");
      // }

      return response.data && response.data?.message
        ? response.data.message
        : "Service deleted successfully";
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to delete service", "error");
      return false;
    }
  };
}

export function confirmedDeleteServiceAction(service) {
  return {
    type: CONFIRMED_DELETE_SERVICES_ACTION,
    payload: service,
  };
}

export function createServiceAction(serviceData, history) {
  return async (dispatch, getState) => {
    try {
      let response = await createService(serviceData);
      if (response) {
        const singleService = {
          serviceData: serviceData,
          imageUrl: response.data.imageUrl,
          title: response.data.title,
        };
        dispatch(confirmedCreateServiceAction(singleService));
        // if (history) {
        //   history.push && history.push("/servicepage");
        // }
        return response.data && response.data?.message
          ? response.data.message
          : "Service created successfully";
      } else {
        // swal("Oops", "Failed to create service", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to create service", "error");
      return false;
    }
  };
}

export function getServicesAction() {
  return async (dispatch, getState) => {
    try {
      let response = await getServices();
      if (response) {
        let services = formatServices(response.data);
        dispatch(confirmedGetServicesAction(services));
        return true;
      } else {
        // swal("Oops", "Failed to get service", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to get service", "error");
      return false;
    }
  };
}

export function confirmedCreateServiceAction(singleService) {
  return {
    type: CONFIRMED_CREATE_SERVICES_ACTION,
    payload: singleService,
  };
}

export function confirmedGetServicesAction(services) {
  return {
    type: CONFIRMED_GET_SERVICES,
    payload: services,
  };
}

export function confirmedUpdateServiceAction(service) {
  return {
    type: CONFIRMED_EDIT_SERVICES_ACTION,
    payload: service,
  };
}

export function updateServiceAction(service, serviceId, history) {
  return async (dispatch, getState) => {
    try {
      let response = await updateService(service, serviceId);
      if (response) {
        dispatch(confirmedUpdateServiceAction(service));
        if (history) {
          history.push && history.push("/servicepage");
        }
        return response.data && response.data?.message
          ? response.data.message
          : "service updated successfully";
      } else {
        // swal("Oops", "Failed to update service", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to update service", "error");
      return false;
    }
  };
}
