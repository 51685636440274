import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  createAdminUserAction,
  updateAdminUserAction,
} from "../../store/actions/AdminUserActions";
import { getRolesAction } from "../../store/actions/RoleActions";
import Swal from "sweetalert2";

const AdminUserOffcanvas = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [showCanvas, setShowCanvas] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    userName: "",
    password: "",
    confirmPassword: "",
    email: "",
    phoneNumber: "",
    roleId: "",
    roleName: "",
    status: "Active",
  });
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState({});
  const [emailExists, setEmailExists] = useState(false);

  useEffect(() => {
    async function getRoles() {
      const roles = await dispatch(getRolesAction());
      setTimeout(() => {
        setRoles(roles);
      }, 500);
    }
    getRoles();
  }, [dispatch]);

  useImperativeHandle(ref, () => ({
    showCanvasModal() {
      setShowCanvas(true);
      setFormData({
        name: props?.selectedAdminUser?.name || "",
        userName: props?.selectedAdminUser?.userName || "",
        password: "",
        confirmPassword: "",
        email: props?.selectedAdminUser?.email || "",
        phoneNumber: props?.selectedAdminUser?.phoneNumber || "",
        status: "Active",
        roleId: props?.selectedAdminUser?.roleId,
        roleName: props?.selectedAdminUser?.roleName
          ? props?.selectedAdminUser?.roleName
          : "",
      });
      setError({});
      setEmailExists(false); // Reset email exists state
    },
    hideCanvas() {
      setShowCanvas(false);
    },
  }));

  useEffect(() => {
    if (props.selectedAdminUser) {
      setFormData({
        ...props.selectedAdminUser,
        password: "",
        confirmPassword: "",
      });
    }
  }, [props.selectedAdminUser]);

  const handleInputChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleRoleChange = (e) => {
    const selectedRole = roles.find((role) => role._id === e.target.value);
    setFormData({
      ...formData,
      roleId: e.target.value,
      roleName: selectedRole ? selectedRole.roleName : "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let validationRules = {
      name: { required: true, minLength: 3 },
      userName: { required: true, minLength: 3 },
      email: { required: true, minLength: 12 },
      phoneNumber: { required: true, minLength: 10 },
      roleId: { required: true },
      // status: { required: true },
    };

    if (!props.selectedAdminUser) {
      validationRules = {
        ...validationRules,
        password: { required: true, minLength: 8 },
        confirmPassword: { required: true, minLength: 8, match: "password" },
      };
    } else {
      validationRules = {
        ...validationRules,
        password: { required: false, minLength: 8 },
        confirmPassword: { required: false, minLength: 8, match: "password" },
      };
    }

    let errors = {};
    Object.keys(validationRules).forEach((field) => {
      const fieldValue = formData[field];
      const rule = validationRules[field];

      if (rule.required && !fieldValue) {
        errors[field] = `Please enter ${field}.`;
      }
      if (rule.minLength && fieldValue.length < rule.minLength) {
        errors[
          field
        ] = `${field} must be at least ${rule.minLength} characters long.`;
      }
      if (rule.match && fieldValue !== formData[rule.match]) {
        errors[field] = `Passwords do not match.`;
      }
    });

    // If the email exists, add an error
    if (emailExists) {
      errors.email = "Email already exists.";
    }

    if (Object.keys(errors).length > 0) {
      setError(errors);
      return;
    }

    const requestBody = {
      name: formData.name,
      userName: formData.userName,
      password: formData.password,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      roleId: formData.roleId,
      roleName: formData.roleName,
      status: formData.status,
    };

    try {
      if (props.selectedAdminUser) {
        let result = await dispatch(
          updateAdminUserAction(requestBody, props.selectedAdminUser._id)
        );

        // Show success alert
        if (result) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `${result}`,
            confirmButtonText: "OK",
          });
          if (props.onSuccess) {
            props.onSuccess();
          }
          setShowCanvas(false);
        }
      } else {
        let result = await dispatch(createAdminUserAction(requestBody));

        if (result) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `${result}`,
            confirmButtonText: "OK",
          });
          if (props.onSuccess) {
            props.onSuccess();
          }
          setShowCanvas(false);
        }
      }
    } catch (error) {
      // Handle error case
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request.",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Offcanvas
      show={showCanvas}
      onHide={() => setShowCanvas(false)}
      className="offcanvas-end custom-offcanvas"
      placement="end"
    >
      <div className="offcanvas-header">
        <h5 className="modal-title">{props.Title}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => setShowCanvas(false)}
        >
          {/* <i className="fa-solid fa-xmark"></i> */}
        </button>
      </div>
      <div className="offcanvas-body">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              value={formData.name}
              onChange={(e) => handleInputChange(e, "name")}
              required
            />
            {error.name && <div className="text-danger">{error.name}</div>}
          </div>
          <div className="mb-3">
            <label>User Name</label>
            <input
              type="text"
              className="form-control"
              value={formData.userName}
              onChange={(e) => handleInputChange(e, "userName")}
              required
            />
            {error.userName && (
              <div className="text-danger">{error.userName}</div>
            )}
          </div>
          <div className="mb-3">
            <label>Password</label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                value={formData.password}
                onChange={(e) => handleInputChange(e, "password")}
                // required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="btn btn-outline-secondary"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            {error.password && (
              <div className="text-danger">{error.password}</div>
            )}
          </div>
          <div className="mb-3">
            <label>Confirm Password</label>
            <div className="input-group">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="form-control"
                value={formData.confirmPassword}
                onChange={(e) => handleInputChange(e, "confirmPassword")}
                // required
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="btn btn-outline-secondary"
              >
                {showConfirmPassword ? "Hide" : "Show"}
              </button>
            </div>
            {error.confirmPassword && (
              <div className="text-danger">{error.confirmPassword}</div>
            )}
          </div>
          <div className="mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={formData.email}
              onChange={(e) => handleInputChange(e, "email")}
              required
            />
            {error.email && <div className="text-danger">{error.email}</div>}
            {emailExists && (
              <div className="text-danger">Email already exists.</div>
            )}
          </div>
          <div className="mb-3">
            <label>Phone Number</label>
            <input
              type="text"
              className="form-control"
              value={formData.phoneNumber}
              onChange={(e) => handleInputChange(e, "phoneNumber")}
              maxLength={10}
            />
            {error.phoneNumber && (
              <div className="text-danger">{error.phoneNumber}</div>
            )}
          </div>
          <div className="mb-3">
            <label>Role</label>
            <select
              className="form-select"
              value={formData.roleId}
              onChange={handleRoleChange}
            >
              <option value="">Select a role</option>
              {roles.map((role) => (
                <option key={role._id} value={role._id}>
                  {role.roleName}
                </option>
              ))}
            </select>
            {error.roleId && <div className="text-danger">{error.roleId}</div>}
          </div>
          {/* <div className="mb-3">
            <label>Status</label>
            <select
              className="form-select"
              value={formData.status}
              onChange={(e) => handleInputChange(e, "status")}
              required
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
            {error.status && <div className="text-danger">{error.status}</div>}
          </div> */}
          <button
            type="submit"
            className="btn btn-primary"
            disabled={emailExists}
          >
            Submit
          </button>
          <button
            type="button"
            className="btn btn-danger ms-2"
            onClick={() => setShowCanvas(false)}
          >
            Cancel
          </button>
        </form>
      </div>
    </Offcanvas>
  );
});

export default AdminUserOffcanvas;
