

import {
  CONFIRMED_CREATE_APPUSER_ACTION,
  CONFIRMED_DELETE_APPUSER_ACTION,
  CONFIRMED_EDIT_APPUSER_ACTION,
  CONFIRMED_GET_APPUSERS,
  CREATE_APPUSER_ACTION,
} from "../actions/AppUserTypes.js";

const initialState = {
  appusers: [],
  
};

export default function AppUserReducer(state = initialState, actions) {
  switch (actions.type) {
    case CREATE_APPUSER_ACTION:
      const newAppUser = {
        id: Math.random(),
        // title: "Banner Title 2asdasd",  add the appuser fi
        // description: "Sample Description 2asdasdas",
      };

      return {
        ...state,
        appusers: [...state.appusers, newAppUser],
      };

    case CONFIRMED_DELETE_APPUSER_ACTION:
      return {
        ...state,
        appusers: state.appusers.filter(
          (appuser) => appuser.id !== actions.payload
        ),
      };

    case CONFIRMED_EDIT_APPUSER_ACTION:
      return {
        ...state,
        appusers: state.appusers.map((appuser) =>
          appuser.id === actions.payload.id ? actions.payload : appuser
        ),
      };

    case CONFIRMED_CREATE_APPUSER_ACTION:
      return {
        ...state,
        appusers: [...state.appusers, actions.payload],
      };

    case CONFIRMED_GET_APPUSERS:
      return {
        ...state,
        appusers: actions.payload,
      };

    default:
      return state;
  }
}
