import axios from "axios";
import { store } from "../store/store";
import { updateToken } from "../store/actions/AuthActions";
import Swal from "sweetalert2";
import { navigateToLogin } from "./navigate";

export const baseURL = `https://api-staging.simpli5.in`;
// export const baseURL = `https://29b8-103-183-229-145.ngrok-free.app`;
// export const baseURL = `https://8eac-103-183-229-145.ngrok-free.app`;
const axiosInstance = axios.create({
  baseURL: baseURL,
  // baseURL: `http://localhost:3000`,
  // baseURL: "https://4c40-115-242-141-38.ngrok-free.app",
});

export const convertToISOString = (date) => {
  return new Date(date).toISOString();
};

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    console.log("State ======", state);
    console.log("config ======", config);

    // Check if auth and authKey exist
    if (state.auth && state.auth.authKey) {
      const token = state.auth.authKey;

      // Add the token to the request headers
      config.headers = {
        ...config.headers,
        authKey: `${token}`, // Or any custom header name if necessary
      };
    }

    return config;
  },
  (error) => {
    console.log("failue =====", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Check if the response has a responseCode that indicates an error, even with status 200
    if (
      response.status === 200 &&
      response.data &&
      response.data.responseCode &&
      response.data.responseCode !== 200
    ) {
      // Handle the API-specific error response
      const errorMessage = response.data.message || "Something went wrong";
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage,
        confirmButtonText: "OK",
      });
      return Promise.reject(new Error(errorMessage)); // Reject the promise to handle it as an error
    }

    // Handle token update if provided in the response headers
    const newToken = response.headers["authkey"];
    if (newToken) {
      store.dispatch(updateToken(newToken)); // Dispatch the action to update the token
    }

    console.log("Updated token =====", newToken);

    return response; // Return the valid response as-is
  },
  (error) => {
    if (error.response) {
      const status = error.response.status;
      const errorMessage = error.response.data.message || "Unknown error";

      // Handle specific error status codes
      if (status === 401) {
        Swal.fire({
          icon: "warning",
          title: "OOPS!! Session expired",
          text: "Try to login again",
          confirmButtonText: "OK",
        });
        localStorage.removeItem("userDetails");
        store.dispatch(updateToken(undefined));
        navigateToLogin(); // Navigate to login page
        return;
      } else if (status === 400) {
        Swal.fire({
          icon: "warning",
          title: "Bad Request!",
          text: "Please check your input.",
          confirmButtonText: "OK",
        });
        return Promise.reject(error);
      } else {
        // Handle other status codes like 500, 404, etc.
        Swal.fire({
          icon: "error",
          title: `Error`,
          text: errorMessage,
          confirmButtonText: "OK",
        });
        return Promise.reject(error);
      }
    } else {
      // Network error or other unknown errors
      Swal.fire({
        icon: "error",
        title: "Network Error",
        text: "Network error or server is down. Please try again later.",
        confirmButtonText: "OK",
      });
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
