import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import RoleOffcanvas from "../../constant/RoleOffcanvas";
import MainPagetitle from "../../layouts/MainPagetitle";
import { connect, useDispatch } from "react-redux";
import {
  createRoleAction,
  deleteRoleAction,
  getRolesAction,
} from "../../../store/actions/RoleActions";
import Swal from "sweetalert2";
import { NUMBER_OF_ITEMS_PER_PAGE } from "../../../constants";
import Loader from "../Loader/Loader";

const Roles = (props) => {
  const [tableData, setTableData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = NUMBER_OF_ITEMS_PER_PAGE; // Changed variable name for clarity
  const roleRef = useRef(null);
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState(undefined);
  const [loader, setLoader] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  useEffect(() => {
    if (loader) {
      document.body.classList.add("blur");
    } else {
      document.body.classList.remove("blur");
    }
  }, [loader]);

  useEffect(() => {
    async function getData() {
      setLoader(true);
      dispatch(getRolesAction());
    }
    getData();
  }, [dispatch]);

  const refreshRoleList = () => {
    dispatch(getRolesAction());
  };

  useEffect(() => {
    setTableData(props.roleData);
    setLoader(props.showLoading);
  }, [props.roleData, props.showLoading]);

  useEffect(() => {
    if (tableData.length > 0) {
      const startIndex = (activePage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const currentPageData = tableData.slice(startIndex, endIndex);
      setPaginatedData(currentPageData);
    }
    // Set data for the current page
  }, [activePage, tableData]);

  useEffect(() => {
    if (selectedRole) {
      roleRef?.current?.showcanvasModal();
    }
  }, [selectedRole]);

  const handleDelete = async (index) => {
    const actualIndex = (activePage - 1) * itemsPerPage + index;
    const toBeDeleted = tableData[actualIndex];

    let result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      let result = await dispatch(deleteRoleAction(toBeDeleted));
      await refreshRoleList();
      Swal.fire({
        icon: "success",
        title: "Deleted!",
        text: `${result}`,
        confirmButtonText: "OK",
      });
    }
  };

  const handleEdit = (index) => {
    const actualIndex = (activePage - 1) * itemsPerPage + index;

    if (tableData[actualIndex]) {
      setSelectedRole(tableData[actualIndex]);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filteredData = props.roleData.filter((item) => {
      return (
        item.roleName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.modules
          .join(" ")
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
    });
    setTableData(filteredData);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
    const sortedData = props.roleData.sort((a, b) => {
      if (sortDirection === "asc") {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    setTableData(sortedData);
  };

  const pagination = Array(Math.ceil(tableData.length / itemsPerPage))
    .fill()
    .map((_, i) => i + 1);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <>
      {loader && <Loader />}
      <MainPagetitle mainTitle="Roles" pageTitle="Roles" parentTitle="Home" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className=" card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Roles</h4>
                    <div className="d-flex align-items-center">
                      <input
                        type="search"
                        value={searchTerm}
                        onChange={handleSearch}
                        placeholder="Search..."
                        className="form-control"
                        style={{ width: "300px" }}
                      />
                      <Link
                        to="#"
                        className="btn btn-primary btn-sm mx-2"
                        onClick={() => {
                          setSelectedRole(undefined);
                          setTimeout(() => {
                            roleRef.current.showcanvasModal();
                          }, 500);
                        }}
                      >
                        + Add Role
                      </Link>
                    </div>
                  </div>

                  <div
                    id="Roles-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
                      <thead>
                        <tr>
                          <th
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "left",
                            }}
                            onClick={() => handleSort("roleName")}
                          >
                            Role Name{" "}
                            {sortColumn === "roleName" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "left",
                            }}
                            onClick={() => handleSort("modules")}
                          >
                            Modules{" "}
                            {sortColumn === "modules" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "center",
                            }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedData &&
                          paginatedData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.roleName}</td>
                              <td>
                                {item.modules ? item.modules.join(" ") : ""}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <Link
                                  to="#"
                                  className="btn btn-primary btn-sm ms-2 me-2"
                                  onClick={() => handleEdit(index)}
                                >
                                  <i className="fa-solid fa-pencil-alt" />
                                </Link>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => handleDelete(index)}
                                >
                                  <i className="fa-solid fa-trash" />
                                </button>
                              </td>
                            </tr>
                          ))}

                        <>
                          {paginatedData.length == 0 && (
                            <tr>
                              <td colSpan="3" style={{ textAlign: "center" }}>
                                Roles not found.
                              </td>
                            </tr>
                          )}
                        </>
                      </tbody>
                    </table>
                  </div>

                  {/* Pagination Controls */}
                  <div
                    className="pagination-controls d-flex justify-content-between"
                    style={{ padding: "15px", textAlign: "right" }}
                  >
                    <ul
                      className="pagination"
                      style={{
                        display: "inline-flex",
                        listStyle: "none",
                        padding: 0,
                      }}
                    >
                      {Array.from({
                        length: Math.ceil(tableData.length / itemsPerPage),
                      }).map((_, i) => (
                        <li
                          key={i + 1}
                          className={`page-item ${
                            activePage === i + 1 ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(i + 1)}
                            style={{
                              border: "1px solid #ddd",
                              padding: "5px 10px",
                              cursor: "pointer",
                            }}
                          >
                            {i + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RoleOffcanvas
        ref={roleRef}
        Title={selectedRole ? "Edit Role" : "Add Role"}
        selectedRole={selectedRole}
        onSuccess={refreshRoleList}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  errorMessage: state.roles.errorMessage,
  successMessage: state.roles.successMessage,
  roleData: state.roles.roles,
  showLoading: state.roles.showLoading,
});

export default connect(mapStateToProps)(Roles);
