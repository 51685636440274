import axiosInstance from "./AxiosInstance";

export function getOrders() {
  let data = {
    // title: "paul walker new",
    // imageUrl:
    //   "https://www.nme.com/news/film/paul-walkers-fast-furious-car-sells-for-555000-at-auction-2975453.jpg",
    // status: "active",
    // deleted: 0,
  };
  return axiosInstance.get(`/orderDetails`, data);
}

export function createOrder(orderData) {
  return axiosInstance.post(`/orderDetails`, orderData);
}

export function isUserExist(username) {
  return axiosInstance.post(`/admin/user-manage/getUserByPhone`, {
    phoneNumber: username,
  });
}

export function updateOrder(orderData, orderId) {
  return axiosInstance.put(
    `/orderDetails/updateOrderDetails/${orderId}`,
    orderData
  );
}

export function updateOrderStatus(orderId, newStatus) {
  return axiosInstance.post(`/orderDetails/${orderId}`, { status: newStatus });
}

export function cancelOrder(order) {
  console.log("order.orderId");
  return axiosInstance.post(`/orderDetails/cancel`, order);
}

export function formatOrders(orderData) {
  let orders = [];
  for (let key in orderData) {
    orders.push({ ...orderData[key], id: key });
  }

  return orders;
}
