import {
  createAdminUser,
  formatAdminUsers,
  getAdminUsers,
  deleteAdminUser,
  updateAdminUser,
} from "../../services/AdminUsersService.js";
import {
  CONFIRMED_CREATE_ADMINUSER_ACTION,
  CONFIRMED_DELETE_ADMINUSER_ACTION,
  CONFIRMED_EDIT_ADMINUSER_ACTION,
  CONFIRMED_GET_ADMINUSERS,
} from "./AdminUserTypes.js";
import swal from "sweetalert";

export function deleteAdminUserAction(adminuser, history) {
  return async (dispatch, getState) => {
    try {
      let response = await deleteAdminUser(adminuser);
      dispatch(confirmedDeleteAdminUserAction(adminuser));
      if (history) {
        history.push("/adminuserpage");
      }
      return response.data && response.data?.message
        ? response.data.message
        : "Admin user deleted successfully";
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to delete admin user", "error");
      return false;
    }
  };
}

export function confirmedDeleteAdminUserAction(adminuser) {
  return {
    type: CONFIRMED_DELETE_ADMINUSER_ACTION,
    payload: adminuser,
  };
}

export function createAdminUserAction(adminuserData, history) {
  return async (dispatch, getState) => {
    try {
      let response = await createAdminUser(adminuserData);
      if (response) {
        const singleAdminUser = {
          adminuserData: adminuserData,
          name: response.data.name,
          userName: response.data.userName,
          password: response.data.password,
          email: response.data.email,
          phoneNumber: response.data.phoneNumber,
          roleName: response.data.roleName,
        };
        dispatch(confirmedCreateAdminUserAction(singleAdminUser));
        if (history) {
          history.push("/adminuserpage");
        }
        return response.data && response.data?.message
          ? response.data.message
          : "Admin user created successfully";
      } else {
        return false;
        // swal("Oops", "Failed to create adminuser", "error");
      }
    } catch (error) {
      console.log(error);
      return false;
      // swal("Oops", "Failed to create adminuser", "error");
    }
  };
}

export function getAdminUsersAction() {
  return async (dispatch, getState) => {
    try {
      let response = await getAdminUsers();
      console.log("get adminuser responce=====", response);
      if (response) {
        let adminusers = formatAdminUsers(response.data);
        dispatch(confirmedGetAdminUsersAction(adminusers));
        return true;
      } else {
        // swal("Oops", "Failed to get adminusers", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to get adminusers", "error");
      return false;
    }
  };
}

export function confirmedCreateAdminUserAction(singleAdminUser) {
  return {
    type: CONFIRMED_CREATE_ADMINUSER_ACTION,
    payload: singleAdminUser,
  };
}

export function confirmedGetAdminUsersAction(adminusers) {
  return {
    type: CONFIRMED_GET_ADMINUSERS,
    payload: adminusers,
  };
}

export function confirmedUpdateAdminUserAction(adminuser) {
  return {
    type: CONFIRMED_EDIT_ADMINUSER_ACTION,
    payload: adminuser,
  };
}

export function updateAdminUserAction(adminuser, adminuserId, history) {
  return async (dispatch, getState) => {
    try {
      let response = await updateAdminUser(adminuser, adminuserId);

      if (response) {
        dispatch(confirmedUpdateAdminUserAction(adminuser));
        if (history) {
          history.push("/adminuserpage");
        }
        return response.data.message
          ? response.data.message
          : "Admin updated successfully";
      } else {
        // swal("Oops", "Failed to update adminusers", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;

      // swal("Oops", "Failed to update adminusers", "error");
    }
  };
}
