export const CREATE_ADMINUSER_ACTION = "[ADMINUSER Action] Create ADMINUSER";
export const CONFIRMED_CREATE_ADMINUSER_ACTION =
  "[ADMINUSER Action] Confirmed Create ADMINUSER";
export const GET_ADMINUSERS = "[ADMINUSER Action] Get ADMINUSERs";
export const CONFIRMED_GET_ADMINUSERS = "[ADMINUSER Action] Confirmed Get ADMINUSERs";
export const EDIT_ADMINUSER_ACTION = "[ADMINUSER Action] Edit ADMINUSER";
export const CONFIRMED_EDIT_ADMINUSER_ACTION =
  "[ADMINUSER Action] Confirmed Edit ADMINUSER";
export const CONFIRMED_DELETE_ADMINUSER_ACTION =
  "[ADMINUSER Action] Confirmed Delete ADMINUSER";
