import {
  CONFIRMED_CREATE_ORDER_ACTION,
  CONFIRMED_CANCEL_ORDER_ACTION,
  CONFIRMED_EDIT_ORDER_ACTION,
  CONFIRMED_GET_ORDERS,
  CREATE_ORDER_ACTION,
  CONFIRMED_UPDATE_ORDER_STATUS_ACTION,
  CONFIRMED_USER_EXIST_ACTION,
} from "../actions/OrderTypes.js";

const initialState = {
  orders: [],
};

export default function OrdersReducer(state = initialState, actions) {
  if (actions.type === CREATE_ORDER_ACTION) {
    const order = {
      id: Math.random(),
      // title: "Banner Title 2asdasd",
      // description: "Sample Description 2asdasdas",
    };

    const orders = [...state.orders];
    orders.push(order);
    return {
      ...state,
      orders,
    };
  }

  if (actions.type === CONFIRMED_USER_EXIST_ACTION) {
    const orders = [...state.orders];
    return {
      ...state,
      orders,
    };
  }

  if (actions.type === CONFIRMED_CANCEL_ORDER_ACTION) {
    const orders = [...state.orders];
    const orderIndex = orders.findIndex(
      (order) => order.id === actions.payload
    );

    orders.splice(orderIndex, 1);

    return {
      ...state,
      orders,
    };
  }
  if (actions.type === CONFIRMED_UPDATE_ORDER_STATUS_ACTION) {
    const orders = [...state.orders];
    const orderIndex = orders.findIndex(
      (order) => order.id === actions.payload.id
    );
  
    orders[orderIndex].status = actions.payload.status;
    orders[orderIndex].updatedAt = actions.payload.updatedAt;
  
    return {
      ...state,
      orders,
    };
  }

  if (actions.type === CONFIRMED_EDIT_ORDER_ACTION) {
    const orders = [...state.orders];
    const orderIndex = orders.findIndex(
      (order) => order.id === actions.payload.id
    );

    orders[orderIndex] = actions.payload;
    return {
      ...state,
      orders,
    };
  }

  if (actions.type === CONFIRMED_CREATE_ORDER_ACTION) {
    const orders = [...state.orders];
    orders.push(actions.payload);

    return {
      ...state,
      orders,
    };
  }

  if (actions.type === CONFIRMED_GET_ORDERS) {
    return {
      ...state,
      orders: actions.payload,
    };
  }
  return state;
}
