export const CREATE_ORDER_ACTION = "[ORDER Action] Create ORDER";
export const CONFIRMED_CREATE_ORDER_ACTION =
  "[ORDER Action] Confirmed Create ORDER";
export const GET_ORDERS = "[ORDER Action] Get ORDERs";
export const CONFIRMED_GET_ORDERS = "[ORDER Action] Confirmed Get ORDERs";
export const EDIT_ORDER_ACTION = "[ORDER Action] Edit ORDER";
export const CONFIRMED_EDIT_ORDER_ACTION =
  "[ORDER Action] Confirmed Edit ORDER";
export const CONFIRMED_CANCEL_ORDER_ACTION =
  "[ORDER Action] Confirmed Cancel ORDER";
export const CONFIRMED_IS_ACTION = "[ORDER Action] Confirmed delete ORDER";

export const CONFIRMED_USER_EXIST_ACTION =
  "[ORDER Action] CONFIRMED_USER_EXIST_ACTION";
  export const CONFIRMED_UPDATE_ORDER_STATUS_ACTION =
  "[ORDER Action] CONFIRMED_UPDATE_ORDER_STATUS_ACTION";