import React from "react";
import { useLocation } from "react-router-dom";

const UserDetails = (props) => {
  console.log("0000000000000000", props);
  const location = useLocation();

  // Destructure userData from location.state
  const { userData } = location.state || {};
  console.log("00000000000000001111111", location);

  // const userData = {
  //   userName: "chaitany",
  //   displayName: "cm",
  //   email: "",
  //   userRole: "user",
  //   userPhonePrimary: "9561154698",
  //   userPhoneVerified: "verified",
  //   stepIdentifier: "6",
  //   createdAt: "28/06/2024 12:57:33",
  //   updatedAt: "28/06/2024 13:05:26",
  //   address: "",
  //   city: "",
  //   state: "",
  //   pincode: "",
  //   dateOfBirth: "",
  //   fontSize: "Small",
  //   brightness: "0.4000000059604645",
  //   emergencyContacts: [
  //     {
  //       category: "Family",
  //       name: "Person 1",
  //       phoneNumber: "1 (234) 56",
  //       _id: "667e67be44fc92537a6910a3",
  //     },
  //     {
  //       category: "Family",
  //       name: "Person 2",
  //       phoneNumber: "456-874",
  //       _id: "667e67be44fc92537a6910a4",
  //     },
  //   ],
  //   quickDial: [
  //     {
  //       name: "Person 3",
  //       phoneNumber: "(874) 563-2154",
  //       _id: "667e682244fc92537a6910c3",
  //     },
  //     {
  //       name: "Person 4",
  //       phoneNumber: "1 (234) 56",
  //       _id: "667e682244fc92537a6910c4",
  //     },
  //   ],
  //   applications: [
  //     {
  //       name: "Calendar",
  //       packageId: "com.google.android.calendar",
  //       _id: "667e67e544fc92537a6910b0",
  //     },
  //     {
  //       name: "Camera",
  //       packageId: "com.android.camera2",
  //       _id: "667e67e544fc92537a6910b1",
  //     },
  //   ],
  //   buddyInterested: "NO",
  //   profileCompletion: 33,
  //   age: null,
  // };

  // Inline CSS styles
  const containerStyle = {
    height: "100vh",
    backgroundColor: "#f9fafb",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const cardStyle = {
    padding: "20px",
    maxWidth: "1600px",
    width: "100%",
    // backgroundColor: '#fff', background color white
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const headerStyle = {
    textAlign: "center",
    marginBottom: "20px",
    fontSize: "1.5rem",
    fontWeight: "normal",
    color: "#2c3e50",
    padding: "10px 0",
    fontFamily: "Arial, sans-serif",
  };

  const sectionTitleStyle = {
    fontSize: "1.0rem",
    color: "#333",
    marginBottom: "10px",
    fontWeight: "normal",
    fontFamily: "Arial, sans-serif",
  };

  const sectionStyle = {
    marginBottom: "20px",
  };

  const infoContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#f1f1f1",
    padding: "15px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  const infoStyle = {
    flex: "1 1 calc(33.333% - 10px)", // Three items per row
    margin: "5px",
    display: "flex",
    alignItems: "center",
  };

  const labelStyle = {
    fontWeight: 600,
    color: "#333",
    marginRight: "10px",
  };

  if (!userData) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <h2 style={headerStyle}>App User Details</h2>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>Personal Details</h2>
          <div style={infoContainerStyle}>
            <div style={infoStyle}>
              <span style={labelStyle}> Name:</span> {userData.userName}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}> Email:</span> {userData.email}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}> phone Number:</span>{" "}
              {userData.userPhoneVerified}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}>Display Name:</span>{" "}
              {userData.displayName}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}>Age:</span> {userData.dateOfBirth}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}> Date of birth :</span>{" "}
              {userData.dateofbirth}
            </div>
          </div>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>Address & Signup Details</h2>
          <div style={infoContainerStyle}>
            <div style={infoStyle}>
              <span style={labelStyle}>Area:</span>{" "}
              {userData.area && userData.area}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}>City:</span>{" "}
              {userData.city && userData.city}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}>State:</span>{" "}
              {userData.state && userData.state}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}>Pincode:</span>{" "}
              {userData.pincode && userData.pincode}
            </div>
            {userData.createdAt && (
              <div style={infoStyle}>
                <span style={labelStyle}>Date of Signup:</span>{" "}
                {new Date(userData.createdAt.toString()).toLocaleDateString() ||
                  "N/A"}
              </div>
            )}
            <div style={infoStyle}>
              <span style={labelStyle}>Profile Completion:</span>{" "}
              {userData.profileCompletion && userData.profileCompletion}%
            </div>
          </div>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>Emergency Details</h2>
          <div style={infoContainerStyle}>
            <div style={infoStyle}>
              <span style={labelStyle}>EMR Name 1:</span>{" "}
              {userData.emergencyContacts[0] &&
                userData.emergencyContacts[0].name}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}>EMR Name 2:</span>{" "}
              {userData.emergencyContacts[1] &&
                userData.emergencyContacts[1].name}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}>EMR Name 3:</span>{" "}
              {userData.emergencyContacts[2] &&
                userData.emergencyContacts[2].name}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}>EMR Number 1:</span>{" "}
              {userData.emergencyContacts[0] &&
                userData.emergencyContacts[0].phoneNumber}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}>EMR Number 2:</span>{" "}
              {userData.emergencyContacts[1] &&
                userData.emergencyContacts[1].phoneNumber}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}>EMR Number 3:</span>{" "}
              {userData.emergencyContacts[2] &&
                userData.emergencyContacts[2].phoneNumber}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}>EMR Type 1:</span>{" "}
              {userData.emergencyContacts[0] &&
                userData.emergencyContacts[0].category}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}>EMR Type 2:</span>{" "}
              {userData.emergencyContacts[1] &&
                userData.emergencyContacts[1].category}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}>EMR Type 3:</span>{" "}
              {userData.emergencyContacts[2] &&
                userData.emergencyContacts[2].category}
            </div>
          </div>
        </div>

        {userData.emergencyAssistance && (
          <div style={sectionStyle}>
            <h2 style={sectionTitleStyle}>Emergency Services</h2>
            <div style={infoContainerStyle}>
              {userData.emergencyAssistance &&
                userData.emergencyAssistance.map((assistance) => {
                  switch (assistance.assistanceType) {
                    case "hospital":
                      return (
                        <>
                          <div style={infoStyle}>
                            <span style={labelStyle}>Hospital :</span>{" "}
                            {assistance.phoneNumber}
                          </div>
                          <div style={infoStyle}>
                            <span style={labelStyle}>Hospital Number:</span>{" "}
                            {assistance.phoneNumber}
                          </div>
                        </>
                      );
                    case "ambulance":
                      return (
                        <>
                          <div style={infoStyle}>
                            <span style={labelStyle}>Ambulance Name :</span>{" "}
                            {/* You can add ambulance name if available */}
                          </div>
                          <div style={infoStyle}>
                            <span style={labelStyle}>Ambulance Number:</span>{" "}
                            {assistance.phoneNumber}
                          </div>
                        </>
                      );
                    case "police":
                      return (
                        <>
                          <div style={infoStyle}>
                            <span style={labelStyle}>Police Name:</span>{" "}
                            {/* You can add police name if available */}
                          </div>
                          <div style={infoStyle}>
                            <span style={labelStyle}>Police Number:</span>{" "}
                            {assistance.phoneNumber}
                          </div>
                        </>
                      );
                    default:
                      return null;
                  }
                })}
            </div>
          </div>
        )}

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>Other Details</h2>
          <div style={infoContainerStyle}>
            <div style={infoStyle}>
              <span style={labelStyle}>S5 buddy Interested:</span>{" "}
              {userData.buddyInterested && userData.buddyInterested}
            </div>
            <div style={infoStyle}>
              <span style={labelStyle}>Default Launcher:</span>{" "}
              {userData.defaultLauncher && userData.defaultLauncher}
            </div>

            <div style={infoStyle}>
              <span style={labelStyle}>Favorite Apps Count:</span>{" "}
              {userData?.applications && userData?.applications.length}
            </div>

            <div style={infoStyle}>
              <span style={labelStyle}>Favorite Apps set:</span>{" "}
              {userData?.applications?.map((dialObject) => {
                return <>{dialObject.name && dialObject.name}</>;
              })}
            </div>

            <div style={infoStyle}>
              <span style={labelStyle}>Quick dial :</span>{" "}
              {userData?.quickdial?.map((dialObject) => {
                return <>{dialObject.name && dialObject.name}</>;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
