import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  // signUp,
} from "../../services/AuthService";
import {
  UPDATE_TOKEN,
  LOGOUT_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOADING_TOGGLE_ACTION,
  LOGIN_FAILED_ACTION,
  store,
  LOGIN_CONFIRMED_ACTION_MODULES,
} from "../store";
import swal from "sweetalert";

// export function signupAction(email, password, navigate) {
//     return (dispatch) => {
//         signUp(email, password)
//         .then((response) => {
//             saveTokenInLocalStorage(response.data);
//             runLogoutTimer(
//                 dispatch,
//                 response.data.expiresIn * 1000,
//                 //history,
//             );
//             dispatch(confirmedSignupAction(response.data));
//             navigate('/dashboard');
// 			//history.push('/dashboard');
//         })
//         .catch((error) => {
//             const errorMessage = formatError(error.response.data);
//             dispatch(signupFailedAction(errorMessage));
//         });
//     };
// }

export function updateToken(token) {
  return (dispatch) => {
    dispatch(updateTokenAction(token));
  };
}

export const updateTokenAction = (token) => {
  return {
    type: UPDATE_TOKEN,
    payload: token,
  };
};

export function Logout(navigate) {
  localStorage.removeItem("userDetails");
  store.dispatch(updateToken(undefined)); // Dispatch the action to update the token
  navigate("/login");
  //history.push('/login');

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, navigate) {
  return async (dispatch) => {
    try {
      let response = await login(email, password);

      if (response) {
        saveTokenInLocalStorage(response.data.admin);
        // runLogoutTimer(dispatch, 1000 * 1000, navigate);
        dispatch(loginConfirmedAction(response.data.admin));
        dispatch(loginConfirmedActionForModules(response.data.modules));

        navigate("/dashboard");
        return response.data.message;
      } else {
        // console.log("error =====", error);
        // console.log("error =====", error.message);
        // const errorMessage = formatError(error);
        // dispatch(loginFailedAction(error.message));
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed login", "error");
      return false;
    }
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function loginConfirmedActionForModules(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION_MODULES,
    payload: data,
  };
}

// export function confirmedSignupAction(payload) {
//     return {
//         type: SIGNUP_CONFIRMED_ACTION,
//         payload,
//     };
// }

// export function signupFailedAction(message) {
//     return {
//         type: SIGNUP_FAILED_ACTION,
//         payload: message,
//     };
// }

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
