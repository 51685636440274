import axiosInstance from "./AxiosInstance";

export function getAdminUsers() {
  let data = {
    // title: "paul walker new",
    // imageUrl:
    //   "https://www.nme.com/news/film/paul-walkers-fast-furious-car-sells-for-555000-at-auction-2975453.jpg",
    status: "active",
    deleted: 0,
  };
  return axiosInstance.get(`/admin/`);
}

export function createAdminUser(adminuserData) {
  return axiosInstance.post(`/admin/register`, adminuserData);
}

export function updateAdminUser(adminuserData, adminuserId) {
  return axiosInstance.put(`/admin/${adminuserId}`, adminuserData);
}

export function deleteAdminUser(adminuser) {
  return axiosInstance.delete(`/admin/${adminuser._id}`, adminuser);
}
export function formatAdminUsers(adminuserData) {
  let adminusers = [];
  for (let key in adminuserData) {
    adminusers.push({ ...adminuserData[key], id: key });
  }

  return adminusers;
}
