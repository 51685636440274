import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import MainPagetitle from "../../layouts/MainPagetitle";
import AdminUserOffcanvas from "../../constant/AdminUserOffcanvas";
import { connect, useDispatch } from "react-redux";
import {
  getAdminUsersAction,
  deleteAdminUserAction,
  createAdminUserAction,
} from "../../../store/actions/AdminUserActions";
import Loader from "../Loader/Loader";
import Swal from "sweetalert2";
import { NUMBER_OF_ITEMS_PER_PAGE } from "../../../constants";

const AdminUsers = (props) => {
  const itemsPerPage = NUMBER_OF_ITEMS_PER_PAGE;
  const [tableData, setTableData] = useState([]); // This will be filtered or paginated data
  const [activePage, setActivePage] = useState(1);
  const [selectedAdminUser, setSelectedAdminUser] = useState(null);
  const adminUserRef = useRef();
  const [pagination, setPagination] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const dispatch = useDispatch();
  const [paginationData, setPaginationData] = useState([]);

  useEffect(() => {
    async function getData() {
      await dispatch(getAdminUsersAction({ refresh: true }));
    }
    getData();
  }, [dispatch]);

  useEffect(() => {
    if (props.adminusers && props.adminusers.length > 0) {
      // Apply filtering by search term before setting pagination
      const filteredData = props.adminusers.filter((user) =>
        user?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      );

      setTableData(filteredData);

      // Create pagination based on filtered data
      const totalPages = Math.ceil(filteredData.length / itemsPerPage);
      const paginationArray = Array(totalPages)
        .fill(0)
        .map((_, i) => i + 1);

      setPagination(paginationArray);

      setActivePage(1); // Reset to first page after search or data change
    } else {
      setTableData([]);
      setPagination([]);
    }
  }, [props.adminusers, searchTerm, itemsPerPage]);

  // This will slice the tableData for the current active page
  useEffect(() => {
    if (tableData.length > 0) {
      const startIndex = (activePage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const currentPageData = tableData.slice(startIndex, endIndex);
      setPaginationData(currentPageData);
    }
    // Set data for the current page
  }, [activePage, tableData]);

  const refreshAdminUserList = async () => {
    await dispatch(getAdminUsersAction({ refresh: true }));
    setActivePage(1); // Reset page to 1 after refresh
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber); // Update active page, pagination useEffect will handle the slicing
  };

  const handleDelete = async (index) => {
    const actualIndex = (activePage - 1) * itemsPerPage + index;
    const toBeDeleted = tableData[actualIndex];

    let result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      let result = await dispatch(deleteAdminUserAction(toBeDeleted));
      await refreshAdminUserList();
      Swal.fire({
        icon: "success",
        title: "Deleted!",
        text: `${result}`,
        confirmButtonText: "OK",
      });
    }
  };

  const handleEditAdminUser = (index) => {
    const actualIndex = (activePage - 1) * itemsPerPage + index;
    setSelectedAdminUser(tableData[actualIndex]);
    adminUserRef.current.showCanvasModal();
  };

  const handleSaveAdminUser = (newAdminUser) => {
    if (selectedAdminUser) {
      dispatch(createAdminUserAction(newAdminUser, selectedAdminUser._id));
    } else {
      dispatch(createAdminUserAction(newAdminUser));
    }
    refreshAdminUserList();
    if (adminUserRef.current) {
      adminUserRef.current.hideCanvas();
    }
  };

  const handleAddAdminUser = () => {
    setSelectedAdminUser(null);
    setTimeout(() => {
      if (adminUserRef.current) {
        adminUserRef.current.showCanvasModal();
      }
    }, 500);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
    const sortedData = tableData.sort((a, b) => {
      if (sortDirection === "asc") {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    setTableData(sortedData);
  };

  return (
    <>
      {!tableData && <Loader></Loader>}
      <MainPagetitle
        mainTitle="Admin Users"
        pageTitle="Admin Users"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Admin Users</h4>
                    <div className="d-flex align-items-center">
                      <input
                        type="search"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="form-control me-2"
                        style={{ width: "300px" }}
                      />
                      <button
                        className="btn btn-primary btn-sm ms-1"
                        onClick={handleAddAdminUser}
                      >
                        <i className="fa-solid fa-user-plus" /> Add Admin User
                      </button>
                    </div>
                  </div>

                  <div
                    id="AppUsers-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
                      <thead>
                        <tr>
                          <th onClick={() => handleSort("name")}>
                            Name{" "}
                            {sortColumn === "name" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th onClick={() => handleSort("userName")}>
                            User Name{" "}
                            {sortColumn === "userName" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th onClick={() => handleSort("email")}>
                            Email{" "}
                            {sortColumn === "email" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th onClick={() => handleSort("phoneNumber")}>
                            Phone Number{" "}
                            {sortColumn === "phoneNumber" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th onClick={() => handleSort("roleName")}>
                            Role Name{" "}
                            {sortColumn === "roleName" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginationData && paginationData.length > 0 ? (
                          paginationData.map((user, index) => (
                            <tr
                              key={user._id}
                              // style={{
                              //   borderBottom: "1px solid #eee", // Line separator for rows
                              // }}
                            >
                              <td>{user?.name}</td>
                              <td>{user?.userName}</td>
                              <td>{user?.email}</td>
                              <td>{user.phoneNumber}</td>
                              <td>{user?.roleName}</td>
                              <td>
                                {user.roleName !== "superAdmin" && (
                                  <>
                                    <Link
                                      to="#"
                                      className="btn btn-primary btn-sm me-2"
                                      onClick={() => handleEditAdminUser(index)}
                                    >
                                      <i className="fa-solid fa-pencil-alt" />
                                    </Link>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() => handleDelete(index)}
                                    >
                                      <i className="fa-solid fa-trash" />
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" style={{ textAlign: "center" }}>
                              Admin users not found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div
                    className="pagination-controls d-flex justify-content-between"
                    style={{ padding: "15px", textAlign: "right" }}
                  >
                    <ul
                      className="pagination"
                      style={{
                        display: "inline-flex",
                        listStyle: "none",
                        padding: 0,
                      }}
                    >
                      {pagination.length > 0 &&
                        pagination.map((page) => (
                          <li
                            key={page}
                            className={`page-item ${
                              activePage === page ? "active" : ""
                            }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => handlePageChange(page)}
                              style={{
                                border: "1px solid #ddd",
                                padding: "5px 10px",
                                cursor: "pointer",
                              }}
                            >
                              {page}
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AdminUserOffcanvas
        ref={adminUserRef}
        Title={selectedAdminUser ? "Edit Admin User" : "Add Admin User"}
        selectedAdminUser={selectedAdminUser}
        onSave={handleSaveAdminUser}
        onSuccess={refreshAdminUserList}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    adminusers: state.adminusers.adminusers || [],
  };
};

export default connect(mapStateToProps)(AdminUsers);
