import {
  CONFIRMED_CREATE_ROLE_ACTION,
  CONFIRMED_DELETE_ROLE_ACTION,
  CONFIRMED_EDIT_ROLE_ACTION,
  CONFIRMED_GET_ROLES,
  CREATE_ROLE_ACTION,
} from "../actions/RoleTypes.js";

const initialState = {
  roles: [],
};

export default function RolesReducer(state = initialState, actions) {
  if (actions.type === CREATE_ROLE_ACTION) {
    const role = {
      id: Math.random(),
      roleName : "superAdmin",
       modules: "CMS",
    };

    const roles = [...state.roles];
    roles.push(role);
    return {
      ...state,
      roles,
    };
  }

  if (actions.type === CONFIRMED_DELETE_ROLE_ACTION) {
    const roles = [...state.roles];
    const roleIndex = roles.findIndex(
      (role) => role.id === actions.payload
    );

    roles.splice(roleIndex, 1);

    return {
      ...state,
      roles,
    };
  }

  if (actions.type === CONFIRMED_EDIT_ROLE_ACTION) {
    const roles = [...state.roles];
    const roleIndex = roles.findIndex(
      (role) => role.id === actions.payload.id
    );

    roles[roleIndex] = actions.payload;
    return {
      ...state,
      roles,
    };
  }

  if (actions.type === CONFIRMED_CREATE_ROLE_ACTION) {
    const roles = [...state.roles];
    roles.push(actions.payload);

    return {
      ...state,
      roles,
    };
  }

  if (actions.type === CONFIRMED_GET_ROLES) {
    return {
      ...state,
      roles: actions.payload,
    };
  }
  return state;
}
