import {
    CONFIRMED_CREATE_ADMINUSER_ACTION,
    CONFIRMED_DELETE_ADMINUSER_ACTION,
    CONFIRMED_EDIT_ADMINUSER_ACTION,
    CONFIRMED_GET_ADMINUSERS,
    CREATE_ADMINUSER_ACTION,
  } from "../actions/AdminUserTypes.js";
  
  const initialState = {
    adminusers: [],
  };
  
  export default function AdminUsersReducer(state = initialState, actions) {
    if (actions.type === CREATE_ADMINUSER_ACTION) {
      const adminuser = {
        id: Math.random(),
        // title: "Banner Title 2asdasd",
        // description: "Sample Description 2asdasdas",
      };
  
      const adminusers = [...state.adminusers];
      adminusers.push(adminuser);
      return {
        ...state,
        adminusers,
      };
    }
  
    if (actions.type === CONFIRMED_DELETE_ADMINUSER_ACTION) {
      const adminusers = [...state.adminusers];
      const adminuserIndex = adminusers.findIndex(
        (adminuser) => adminuser.id === actions.payload
      );
  
      adminusers.splice(adminuserIndex, 1);
  
      return {
        ...state,
        adminusers,
      };
    }
  
    if (actions.type === CONFIRMED_EDIT_ADMINUSER_ACTION) {
      const adminusers = [...state.adminusers];
      const adminuserIndex = adminusers.findIndex(
        (adminuser) => adminuser.id === actions.payload.id
      );
  
      adminusers[adminuserIndex] = actions.payload;
      return {
        ...state,
        adminusers,
      };
    }
  
    if (actions.type === CONFIRMED_CREATE_ADMINUSER_ACTION) {
      const adminusers = [...state.adminusers];
      adminusers.push(actions.payload);
  
      return {
        ...state,
        adminusers,
      };
    }
  
    if (actions.type === CONFIRMED_GET_ADMINUSERS) {
      return {
        ...state,
        adminusers: actions.payload,
      };
    }
    return state;
  }
  