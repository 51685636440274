import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createRoleAction, updateRoleAction } from "../../store/actions/RoleActions";
import Swal from 'sweetalert2'; // Import SweetAlert2

const RoleOffcanvas = forwardRef((props, ref) => {
  const [showRoleCanvas, setShowRoleCanvas] = useState(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    roleName: "",
    modules: [],
  });

  useEffect(() => {
    if (props.selectedRole) {
      setFormData({
        roleName: props.selectedRole.roleName || "",
        modules: props.selectedRole.modules || [],
      });
    } else {
      setFormData({ roleName: "", modules: [] });
    }
  }, [props.selectedRole]);

  useImperativeHandle(ref, () => ({
    showcanvasModal() {
      setShowRoleCanvas(true);
    },
    hideCanvas() {
      setShowRoleCanvas(false);
    },
  }));

  const validateForm = () => {
    const newErrors = {};
    if (!formData.roleName) {
      newErrors.roleName = "Role name is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setFormData({ ...formData, [field]: value });
    if (field === "roleName" && value) {
      setErrors({ ...errors, roleName: undefined });
    }
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => {
      const updatedModules = checked
        ? [...prev.modules, value]
        : prev.modules.filter((module) => module !== value);

      return { ...prev, modules: updatedModules };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const requestBody = {
      roleName: formData.roleName,
      modules: formData.modules,
    };

    try {
      if (props.selectedRole) {
        await dispatch(updateRoleAction(requestBody, props.selectedRole._id));
      } else {
        await dispatch(createRoleAction(requestBody));
      }

      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: props.selectedRole ? 'Role updated successfully.' : 'Role created successfully.',
        confirmButtonText: 'OK'
      });

      setShowRoleCanvas(false);
      if (props.onSuccess) {
        props.onSuccess();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while processing your request.',
        confirmButtonText: 'OK'
      });
    }
  };

  return (
    <Offcanvas
      show={showRoleCanvas}
      onHide={() => setShowRoleCanvas(false)}
      className="offcanvas-end custom-offcanvas"
      placement="end"
    >
      <div className="offcanvas-header">
        <h5 className="modal-title">{props.Title}</h5>
        <button type="button" className="btn-close" onClick={() => setShowRoleCanvas(false)} />
      </div>
      <div className="offcanvas-body">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label>
              Role Name <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errors.roleName ? "border-danger" : ""}`}
              value={formData.roleName}
              onChange={(e) => handleInputChange(e, "roleName")}
            />
            {errors.roleName && (
              <small className="text-danger">{errors.roleName}</small>
            )}
          </div>

          <div className="d-flex flex-column mb-3">
            <label>Permissions</label>
            <div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="modules"
                  value="CMS"
                  checked={formData.modules.includes("CMS")} 
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label">CMS</label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="modules"
                  value="S5-Buddy"
                  checked={formData.modules.includes("S5-Buddy")} 
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label">S5 Buddy</label>
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-primary me-1 m-t30">
            Submit
          </button>
          <button
            type="button"
            onClick={() => setShowRoleCanvas(false)}
            className="btn btn-danger ms-1"
          >
            Cancel
          </button>
        </form>
      </div>
    </Offcanvas>
  );
});

export default RoleOffcanvas;
