import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MainPagetitle from "../../layouts/MainPagetitle";
import { connect, useDispatch } from "react-redux";
import {
  getOrdersAction,
  cancelOrderAction,
  createOrderAction,
} from "../../../store/actions/OrderActions";
import Loader from "../Loader/Loader";
import Swal from "sweetalert2";
import { NUMBER_OF_ITEMS_PER_PAGE } from "../../../constants";

export const OrderStatus = {
  New: "NEW",
  InProgress: "InProgress",
  Delivered: "Delivered",
  Cancelled: "Cancelled",
};

const orderStatusColors = {
  [OrderStatus.NEW]: {
    color: "#2E8BC0", // darker shade of #54B7D3
    borderColor: "#ffc107",
    backgroundColor: "rgba(84, 183, 211, 0.1)",
  },
  [OrderStatus.InProgress]: {
    color: "#FF9900", // darker shade of #FFC107
    borderColor: "#17a2b8",
    backgroundColor: "rgba(255, 193, 7, 0.1)",
  },
  [OrderStatus.Delivered]: {
    color: "#1E7E34", // darker shade of #28A745
    borderColor: "#28a745",
    backgroundColor: "rgba(40, 167, 69, 0.1)",
  },
  [OrderStatus.Cancelled]: {
    color: "#B30000", // darker shade of #DC3545
    borderColor: "#dc3545",
    backgroundColor: "rgba(220, 53, 69, 0.1)",
  },
};

export const PaymentStatus = {
  Paid: "paid",
  Pending: "pending",
};

const paymentStatusColors = {
  [PaymentStatus.Pending]: {
    color: "#FFC107", // darker shade of #FFC107
    borderColor: "#FFC107",
    backgroundColor: "rgba(255, 193, 7, 0.1)",
  },
  [PaymentStatus.Paid]: {
    color: "#28A745", // darker shade of #28A745
    borderColor: "#28A745",
    backgroundColor: "rgba(40, 167, 69, 0.1)",
  },
  // [PaymentStatus.Completed]: {
  //   color: "#17A2B8", // darker shade of #17A2B8
  //   borderColor: "#17A2B8",
  //   backgroundColor: "rgba(23, 162, 184, 0.1)",
  // },
};

const Orders = (props) => {
  const itemsPerPage = NUMBER_OF_ITEMS_PER_PAGE;
  const [tableData, setTableData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState();
  const navigate = useNavigate();
  const [paginatedData, setPaginatedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  useEffect(() => {
    if (loader) {
      document.body.classList.add("blur");
    } else {
      document.body.classList.remove("blur");
    }
  }, [loader]);

  // Fetch orders on initial render
  useEffect(() => {
    setLoader(true);
    async function getData() {
      dispatch(getOrdersAction());
    }
    getData();
  }, [dispatch]);

  // Update local state whenever props change
  useEffect(() => {
    if (props.orders && props.orders.length > 0) {
      setTableData(props.orders);
    } else {
      setTableData([]);
    }
    setLoader(false);
  }, [props.orders]);

  const refreshOrderList = () => {
    dispatch(getOrdersAction());
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleCancel = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const actualIndex = (activePage - 1) * itemsPerPage + index;
        const toBeCanceled = tableData[actualIndex];
        console.log("Index ", toBeCanceled);
        dispatch(cancelOrderAction(toBeCanceled));
        refreshOrderList();
        Swal.fire("Canceled!", "Your order has been canceled.", "success");
      }
    });
  };

  const handleEditOrder = (order, index) => {
    console.log("Edit item at index:", index);
    const actualIndex = (activePage - 1) * itemsPerPage + index; // Calculate the correct index
    setSelectedOrder(tableData[actualIndex]);
    navigate("/orderdetail", {
      state: { orderData: order },
    });
  };

  const handleSaveOrder = (newOrder) => {
    if (selectedOrder) {
      dispatch(createOrderAction(newOrder, selectedOrder.orderId));
    } else {
      dispatch(createOrderAction(newOrder));
    }
    refreshOrderList();
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  useEffect(() => {
    const filteredData = tableData.filter((order) => {
      return (
        order.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.phoneNumber.includes(searchTerm) ||
        order.S5Id.includes(searchTerm) ||
        order.tata1MgOrderId.includes(searchTerm) ||
        order?.orderDate.toString().includes(searchTerm) ||
        order.additionalInformation.paymentStatus.includes(searchTerm) ||
        order.additionalInformation.orderStatus.includes(searchTerm)
      );
    });

    const sortedData = filteredData.sort((a, b) => {
      if (sortColumn === "userName") {
        return sortDirection === "asc"
          ? a.userName.localeCompare(b.userName)
          : b.userName.localeCompare(a.userName);
      } else if (sortColumn === "phoneNumber") {
        return sortDirection === "asc"
          ? a.phoneNumber.localeCompare(b.phoneNumber)
          : b.phoneNumber.localeCompare(a.phoneNumber);
      } else if (sortColumn === "S5Id") {
        return sortDirection === "asc"
          ? a.S5Id.localeCompare(b.S5Id)
          : b.S5Id.localeCompare(a.S5Id);
      } else if (sortColumn === "tata1MgOrderId") {
        return sortDirection === "asc"
          ? a.tata1MgOrderId.localeCompare(b.tata1MgOrderId)
          : b.tata1MgOrderId.localeCompare(a.tata1MgOrderId);
      } else if (sortColumn === "orderDate") {
        return sortDirection === "asc"
          ? a.orderDate - b.orderDate
          : b.orderDate - a.orderDate;
      } else if (sortColumn === "paymentStatus") {
        return sortDirection === "asc"
          ? a.additionalInformation.paymentStatus.localeCompare(
              b.additionalInformation.paymentStatus
            )
          : b.additionalInformation.paymentStatus.localeCompare(
              a.additionalInformation.paymentStatus
            );
      } else if (sortColumn === "orderStatus") {
        return sortDirection === "asc"
          ? a.additionalInformation.orderStatus.localeCompare(
              b.additionalInformation.orderStatus
            )
          : b.additionalInformation.orderStatus.localeCompare(
              a.additionalInformation.orderStatus
            );
      } else {
        return 0;
      }
    });

    const startIndex = (activePage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = sortedData.slice(startIndex, endIndex);

    setPaginatedData(paginatedData);
  }, [tableData, searchTerm, sortColumn, sortDirection, activePage]);

  const handleAddOrder = () => {
    setSelectedOrder(null); // Reset the selected order for a new one
    // Add any additional logic for adding a new order
  };

  return (
    <>
      {loader && <Loader />}
      <MainPagetitle mainTitle="Orders" pageTitle="Orders" parentTitle="Home" />
      <div className="container-fluid" style={{ padding: "15px" }}>
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div
              className="card"
              style={{
                border: "1px solid #ddd",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <div className="card-body p-0">
                <div className="table-responsive" style={{ margin: "0" }}>
                  <div
                    className="tbl-caption d-flex justify-content-between align-items-center"
                    style={{ padding: "15px" }}
                  >
                    <h4 className="heading mb-0">Orders</h4>
                    <div className="d-flex align-items-center">
                      <input
                        type="search"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="form-control me-2"
                        style={{ width: "300px" }}
                      />
                      <Link
                        to="/orderdetail"
                        className="btn btn-primary btn-sm ms-1"
                      >
                        <i className="fa-solid fa-plus" /> Add Order
                      </Link>
                    </div>
                  </div>

                  <div
                    className="dataTables_wrapper no-footer"
                    style={{ padding: "15px" }}
                  >
                    <table
                      className="table dataTable no-footer mb-0"
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        backgroundColor: "#fff",
                        color: "#858796",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "left",
                            }}
                            onClick={() => handleSort("orderDate")}
                          >
                            Date
                            {sortColumn === "orderDate" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "left",
                            }}
                            onClick={() => handleSort("S5Id")}
                          >
                            s5 ID
                            {sortColumn === "S5Id" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "left",
                            }}
                            onClick={() => handleSort("tata1MgOrderId")}
                          >
                            Tata Order ID
                            {sortColumn === "tata1MgOrderId" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "left",
                            }}
                            onClick={() => handleSort("userName")}
                          >
                            Name
                            {sortColumn === "userName" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "left",
                            }}
                            onClick={() => handleSort("phoneNumber")}
                          >
                            Phone Number
                            {sortColumn === "phoneNumber" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "left",
                            }}
                            onClick={() =>
                              handleSort("additionalInformation.totalAmount")
                            }
                          >
                            Amount
                            {sortColumn ===
                            "additionalInformation.totalAmount" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "left",
                            }}
                            onClick={() => handleSort("paymentStatus")}
                          >
                            Payment Status
                            {sortColumn === "paymentStatus" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "left",
                            }}
                            onClick={() => handleSort("orderStatus")}
                          >
                            Order Status
                            {sortColumn === "orderStatus" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "left",
                            }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedData.map((order, index) => (
                          <tr
                            key={order.orderId}
                            style={{ borderBottom: "1px solid #eee" }}
                          >
                            <td style={{ padding: "10px" }}>
                              {new Date(
                                order?.orderDate?.toString()
                              ).toLocaleDateString() || "N/A"}
                            </td>
                            <td style={{ padding: "10px" }}>{order?.S5Id}</td>
                            <td style={{ padding: "10px" }}>
                              {order?.tata1MgOrderId}
                            </td>
                            <td style={{ padding: "10px" }}>
                              {order?.userName}
                            </td>
                            <td style={{ padding: "10px" }}>
                              {order?.phoneNumber}
                            </td>
                            <td style={{ padding: "10px" }}>
                              &#8377;{" "}
                              {parseFloat(
                                order?.additionalInformation.totalAmount
                              ).toFixed(2)}
                            </td>
                            <td style={{ padding: "10px" }}>
                              <span
                                style={{
                                  ...paymentStatusColors[
                                    order.additionalInformation.paymentStatus.toLowerCase()
                                  ],
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  borderWidth: 1,
                                }}
                              >
                                {order.additionalInformation.paymentStatus ||
                                  "N/A"}
                              </span>
                            </td>
                            <td style={{ padding: "10px" }}>
                              <span
                                style={{
                                  ...orderStatusColors[
                                    order.additionalInformation.orderStatus
                                  ],
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  borderWidth: 1,
                                }}
                              >
                                {order.additionalInformation.orderStatus ||
                                  "N/A"}
                              </span>
                            </td>
                            <td style={{ padding: "10px" }}>
                              <Link
                                to="/orderdetail"
                                className="btn btn-primary btn-sm me-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleEditOrder(order, index);
                                }}
                              >
                                <i className="fa-solid fa-pencil-alt" />
                              </Link>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleCancel(index)}
                              >
                                <i className="fa-solid fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                        <>
                          {paginatedData.length == 0 && (
                            <tr>
                              <td colSpan="8" style={{ textAlign: "center" }}>
                                Orders not found.
                              </td>
                            </tr>
                          )}
                        </>
                      </tbody>
                    </table>
                  </div>

                  {/* Pagination Controls */}
                  <div
                    className="pagination-controls d-flex justify-content-between"
                    style={{ padding: "15px", textAlign: "right" }}
                  >
                    <ul
                      className="pagination"
                      style={{
                        display: "inline-flex",
                        listStyle: "none",
                        padding: 0,
                      }}
                    >
                      {Array.from({
                        length: Math.ceil(tableData.length / itemsPerPage),
                      }).map((_, i) => (
                        <li
                          key={i + 1}
                          className={`page-item ${
                            activePage === i + 1 ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(i + 1)}
                            style={{
                              border: "1px solid #ddd",
                              padding: "5px 10px",
                              cursor: "pointer",
                            }}
                          >
                            {i + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders.orders || [],
  };
};

export default connect(mapStateToProps)(Orders);
