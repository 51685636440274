import {
  createBanner,
  formatBanners,
  getBanners,
  deleteBanner,
  updateBanner,
} from "../../services/BannersService";
import {
  CONFIRMED_CREATE_BANNER_ACTION,
  CONFIRMED_DELETE_BANNER_ACTION,
  CONFIRMED_EDIT_BANNER_ACTION,
  CONFIRMED_GET_BANNERS,
} from "./BannerTypes";
import swal from "sweetalert";

export function deleteBannerAction(banner, history) {
  return async (dispatch, getState) => {
    try {
      const response = await deleteBanner(banner);
      dispatch(confirmedDeleteBannerAction(banner));
      // if (history) {
      //   history.push("/bannerpage");
      // }
      return response.data && response.data?.message
        ? response.data?.message
        : "Banner deleted successfully";
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to delete banner", "error");
      return false;
    }
  };
}

export function confirmedDeleteBannerAction(banner) {
  return {
    type: CONFIRMED_DELETE_BANNER_ACTION,
    payload: banner,
  };
}

export function createBannerAction(bannerData, history) {
  return async (dispatch, getState) => {
    try {
      const response = await createBanner(bannerData);
      if (response) {
        const singleBanner = {
          bannerData: bannerData,
          imageUrl: response.data.imageUrl,
          title: response.data.title,
        };
        dispatch(confirmedCreateBannerAction(singleBanner));
        // if (history) {
        //   history.push("/bannerpage");
        // }
        return response.data && response.data?.message
          ? response.data.message
          : "Banner created successfully";
      } else {
        // swal("Oops", "Failed to create banner", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      // swal("Oops", "Failed to create banner", "error");
      return false;
    }
  };
}

export function getBannersAction() {
  return async (dispatch, getState) => {
    try {
      let response = await getBanners();
      console.log("get banner responce=====", response);
      if (response) {
        let banners = formatBanners(response.data);
        dispatch(confirmedGetBannersAction(banners));
        return response.data.message;
      } else {
        swal("Oops", "Failed to get banners", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      swal("Oops", "Failed to get banners", "error");
      return false;
    }
  };
}

export function confirmedCreateBannerAction(singleBanner) {
  return {
    type: CONFIRMED_CREATE_BANNER_ACTION,
    payload: singleBanner,
  };
}

export function confirmedGetBannersAction(banners) {
  return {
    type: CONFIRMED_GET_BANNERS,
    payload: banners,
  };
}

export function confirmedUpdateBannerAction(banner) {
  return {
    type: CONFIRMED_EDIT_BANNER_ACTION,
    payload: banner,
  };
}

export function updateBannerAction(banner, bannerId, history) {
  return async (dispatch, getState) => {
    try {
      let response = await updateBanner(banner, bannerId);
      if (response) {
        dispatch(confirmedUpdateBannerAction(banner));
        if (history) {
          history.push("/bannerpage");
        }
        return response.data && response.data?.message
          ? response.data.message
          : "Banner udpated successfully";
      } else {
        swal("Oops", "Failed to update banners", "error");
        return false;
      }
    } catch (error) {
      console.log(error);
      swal("Oops", "Failed to update banners", "error");
      return false;
    }
  };
}
