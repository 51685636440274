export const CREATE_APPUSER_ACTION = "[APPUSER Action] Create APPUSER";
export const CONFIRMED_CREATE_APPUSER_ACTION =
  "[APPUSER Action] Confirmed Create APPUSER";
export const GET_APPUSERS = "[APPUSER Action] Get APPUSERs";
export const CONFIRMED_GET_APPUSERS = "[APPUSER Action] Confirmed Get APPUSERs";
export const EDIT_APPUSER_ACTION = "[APPUSER Action] Edit APPUSER";
export const CONFIRMED_EDIT_APPUSER_ACTION =
  "[APPUSER Action] Confirmed Edit APPUSER";
export const CONFIRMED_DELETE_APPUSER_ACTION =
  "[APPUSER Action] Confirmed Delete APPUSER";
