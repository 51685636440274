import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, Logout } from "../store/actions/AuthActions";
import axiosInstance from "./AxiosInstance";

// export function signUp(email, password) {
//     //axios call
//     const postData = {
//         email,
//         password,
//         returnSecureToken: true,
//     };
//     return axios.post(
//         `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
//         postData,
//     );
// }

export function login(email, password) {
  const postData = {
    userName: email,
    password,
  };

  return axiosInstance.post("/admin/login", postData);
}

export function formatError(errorResponse) {
  switch (errorResponse.message) {
    case "EMAIL_EXISTS":
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;

    case "Network Error":
      swal("Oops", "Network Error", "error", { button: "Try Again!" });
      break;

    case "USER_DISABLED":
      return "User Disabled";

    default:
      swal(
        "Oops",
        `${errorResponse.response.data.message}`,
        `${errorResponse.response.data.message}`,
        {
          button: "Try Again!",
        }
      );

      return;
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  // tokenDetails.expireDate = new Date(
  //   new Date().getTime() + tokenDetails.expiresIn * 1000
  // );
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    // dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  let tokenDetails = "";
  if (!tokenDetailsString || tokenDetailsString == "undefined") {
    dispatch(Logout(navigate));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  // if (todaysDate > expireDate) {
  //   dispatch(Logout(navigate));
  //   return;
  // }

  dispatch(loginConfirmedAction(tokenDetails));

  // const timer = expireDate.getTime() - todaysDate.getTime();
  // runLogoutTimer(dispatch, timer, navigate);
}

export function isLogin() {
  const tokenDetailsString = localStorage.getItem("userDetails");

  if (tokenDetailsString) {
    return true;
  } else {
    return false;
  }
}
