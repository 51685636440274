import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  createBannerAction,
  updateBannerAction,
} from "../../store/actions/BannerActions";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2"; // Import SweetAlert2
import { s5BucketBaseUrl } from "../components/Cms/AddService";

const statusOptions = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

const urlTypeOptions = [
  { value: "external", label: "External" },
  { value: "internal", label: "Internal" },
];

const BannerOffcanvas = forwardRef((props, ref) => {
  const [addBanners, setAddBanners] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [imageLoader, setImageLoader] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    imageUrl: "",
    title: "",
    status: true,
    redirectionUrl: "",
    urlType: "external",
  });

  useEffect(() => {
    if (props.selectedBanner) {
      setFormData({
        title: props.selectedBanner.title || "",
        imageUrl: props.selectedBanner.imageUrl || "",
        status: props.selectedBanner.status ? true : false,
        redirectionUrl: props.selectedBanner.redirectionUrl,
        urlType: props.selectedBanner.urlType?.toLowerCase() || "external",
      });
    } else {
      setFormData({
        title: "",
        imageUrl: "",
        status: true,
        redirectionUrl: "",
        urlType: "external",
      });
    }
    const newErrors = { ...errors };
    delete newErrors.imageUrl;
    setErrors(newErrors);
  }, [props.selectedBanner]);

  useImperativeHandle(ref, () => ({
    showEmployeModal() {
      setAddBanners(true);
    },
  }));

  const nav = useNavigate();

  function isValidUrl(string) {
    if (string === "") {
      return true;
    }
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setFormData({ ...formData, [field]: value });
    const newErrors = { ...errors };

    if (field === "title" && value) {
      delete newErrors.title;
    }
    if (field === "redirectionUrl" && isValidUrl(value)) {
      delete newErrors.redirectionUrl;
    }
    setErrors(newErrors);
  };

  const handleUpload = async (file, type) => {
    if (!file) return;

    try {
      setImageLoader(true);

      const timestamp = new Date().getTime();
      const response = await axios.post(
        `https://api-staging.simpli5.in/upload/generatPresignUrl`,
        {
          fileName: `${type}/image/${timestamp}.png`,
        }
      );

      const url = response.data.presignedUrl;

      const uploadResponse = await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      if (uploadResponse.status === 200) {
        let imageUrl = `${s5BucketBaseUrl}/${type}/image/${timestamp}.png`;
        setFormData({
          ...formData,
          imageUrl: imageUrl,
        });
        setImageUrl(url);
      }

      setImageLoader(false);
    } catch (error) {
      alert("Upload failed.");
      setImageLoader(false);
    }
  };

  const handleImageUpload = (e) => {
    setImageLoader(true);
    setImageUrl(undefined);
    const newErrors = { ...errors };
    delete newErrors.imageUrl;
    setErrors(newErrors);
    const files = e.target.files;

    if (files && files[0]) {
      handleUpload(files[0], "banner");
    } else {
      setError("Please upload a valid JPG or PNG for Brand Image.");
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.title) {
      newErrors.title = "Title is required";
    }
    if (!isValidUrl(formData.redirectionUrl)) {
      newErrors.redirectionUrl = "Please enter a valid URL";
    }
    if (formData.imageUrl === "") {
      newErrors.imageUrl = "Image is mandatory";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoader(true);

    try {
      let result;
      if (props.selectedBanner) {
        result = await dispatch(
          updateBannerAction(formData, props.selectedBanner._id)
        );
      } else {
        result = await dispatch(createBannerAction(formData));
      }

      if (result) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: props.selectedBanner
            ? "Banner updated successfully."
            : "Banner created successfully.",
          confirmButtonText: "OK",
        });

        if (props.onSuccess) {
          setTimeout(() => {
            setErrors({}); // Remove error message
            props.onSuccess();
            setAddBanners(false);
          }, 1000);
        }
      } else {
        throw new Error("Operation failed");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request.",
        confirmButtonText: "OK",
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Offcanvas
        show={addBanners}
        onHide={() => setAddBanners(false)}
        className="offcanvas-end custom-offcanvas"
        placement="end"
      >
        <div className="offcanvas-header">
          <h5 className="modal-title">{props.Title}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setAddBanners(false)}
          />
        </div>
        <div className="offcanvas-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label>
                Title <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors.title ? "border-danger" : ""
                }`}
                value={formData.title}
                onChange={(e) => handleInputChange(e, "title")}
              />
              {errors.title && (
                <small className="text-danger">{errors.title}</small>
              )}
            </div>
            <div className="d-flex flex-column mb-3">
              <label>Brand Image (Upload)</label>
              <input
                type="file"
                id="brandImageUpload"
                className="d-none"
                accept=".png,.jpg,.jpeg"
                onChange={handleImageUpload}
              />
              <button
                type="button"
                className="btn btn-info mb-2"
                onClick={() =>
                  document.getElementById("brandImageUpload").click()
                }
              >
                {!imageUrl ? "Upload Image" : "Replace Image"}
              </button>

              {imageLoader && <div>Uploading...</div>}

              {!imageLoader && formData.imageUrl && (
                <div>
                  <img
                    src={formData.imageUrl}
                    alt="Brand Preview"
                    style={{
                      height: "200px",
                      marginTop: "10px",
                      aspectRatio: "16/9",
                    }}
                  />
                </div>
              )}
              {errors.imageUrl && (
                <small className="text-danger">{errors.imageUrl}</small>
              )}
            </div>

            <div className="d-flex flex-column mb-3">
              <label className="form-label">Redirection URL</label>
              <input
                type="text"
                className={`form-control ${
                  errors.redirectionUrl ? "border-danger" : ""
                }`}
                value={formData.redirectionUrl}
                onChange={(e) => {
                  if (isValidUrl(e.target.value)) {
                    delete errors.redirectionUrl;
                  }
                  setFormData({
                    ...formData,
                    redirectionUrl: e.target.value,
                  });
                }}
                placeholder="Enter redirection URL"
              />
              {errors.redirectionUrl && (
                <small className="text-danger">{errors.redirectionUrl}</small>
              )}
            </div>

            <div className="d-flex flex-column mb-3">
              <label className="form-label">URL Type</label>
              <Select
                options={urlTypeOptions}
                className="custom-react-select"
                value={urlTypeOptions.find(
                  (option) => option.value === formData.urlType
                )}
                onChange={(selectedOption) =>
                  setFormData({
                    ...formData,
                    urlType: selectedOption.value,
                  })
                }
                isSearchable={false}
              />
            </div>

            <div className="d-flex flex-column mb-3">
              <label className="form-label">Status</label>
              <Select
                options={statusOptions}
                className="custom-react-select"
                value={statusOptions.find(
                  (option) => option.value === formData.status
                )}
                onChange={(selectedOption) =>
                  setFormData({
                    ...formData,
                    status: selectedOption.value,
                  })
                }
                isSearchable={false}
              />
            </div>

            <button type="submit" className="btn btn-primary me-1 m-t30">
              {loader ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Submit"
              )}
            </button>
            <Link
              to="#"
              onClick={() => setAddBanners(false)}
              className="btn btn-danger ms-1"
            >
              Cancel
            </Link>
          </form>
        </div>
      </Offcanvas>
    </>
  );
});

export default BannerOffcanvas;
