import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import {
  createOrderAction,
  isUserExistAction,
  updateOrderAction,
} from "../../../store/actions/OrderActions";
import { useDispatch } from "react-redux";
import { convertToISOString } from "../../../services/AxiosInstance";
import axios from "axios";
import { s5BucketBaseUrl } from "../Cms/AddService";
import { OrderStatus, PaymentStatus } from "./Order";
import swal from "sweetalert";
import Swal from "sweetalert2";
const formatDateTimeLocal = (date) => {
  const padZero = (num) => (num < 10 ? `0${num}` : num);

  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1); // Months are zero-indexed
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
const OrderDetails = () => {
  const [formData, setFormData] = useState({
    id: "",
    user: {
      name: "",
      mobile: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    },
    prescription: null,
    orderDate: null,
    mgDate: null,
    chatStartDateTime: null,
    chatEndDateTime: null,
    deliveryDate: null,
    status: "",
    paymentStatus: "",
    paymentMode: "",
    couponcode: "",
    transactionNo: "",
    items: [
      {
        medicine: "",
        strength: "",
        packageSize: "",
        numberOfQuantities: "",
        mrp: "",
        discountPrice: "",
        frequency: "weekly",
        notificationDate: null,
      },
    ],
    totalAmount: "",
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imageLoader, setImageLoader] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [isFromEdit, setIsFromEdit] = useState();
  const [loader, setLoader] = useState(false);

  const location = useLocation();

  // Destructure userData from location.state
  const { orderData } = location.state || {};

  const setFormDataFromOrderData = (orderData) => {
    const formData = {
      id: orderData?.tata1MgOrderId,
      orderId: orderData.orderId,
      user: {
        name: orderData.userName || "",
        mobile: orderData.phoneNumber || "",
        addressLine1: orderData.addressDetails?.addressLine1 || "",
        addressLine2: orderData.addressDetails?.addressLine2 || "",
        area: orderData.addressDetails?.area || "",
        country: orderData.addressDetails?.country || "",
        state: orderData.addressDetails?.state || "",
        city: orderData.addressDetails?.city || "",
        pincode: orderData.addressDetails?.pincode || "",
      },
      prescription: orderData.prescriptionDetails?.fileURL || null,
      hasPrescription: orderData.prescriptionDetails?.havePrescription || null,

      orderDate: orderData.orderDate ? new Date(orderData.orderDate) : null,
      mgDate: orderData.tata1MgOrderDate
        ? new Date(orderData.tata1MgOrderDate)
        : null,
      chatStartDateTime: orderData.additionalInformation?.chatStartDateTime
        ? new Date(orderData.additionalInformation.chatStartDateTime)
        : null,
      chatEndDateTime: orderData.additionalInformation?.chatEndDateTime
        ? new Date(orderData.additionalInformation.chatEndDateTime)
        : null,
      deliveryDate: orderData.additionalInformation?.deliveryDate
        ? new Date(orderData.additionalInformation.deliveryDate)
        : null,
      status: orderData.additionalInformation?.orderStatus || "",
      paymentStatus: orderData.additionalInformation?.paymentStatus || "",
      paymentMode: orderData.additionalInformation?.paymentMode || "",
      couponcode: orderData.additionalInformation?.couponCode || "",
      transactionNo: orderData.additionalInformation?.transactionNumber || "",
      totalAmount: orderData.additionalInformation?.totalAmount || "",

      items:
        orderData.orderItems?.map((item) => ({
          medicine: item.medicineName || "",
          strength: item.medicineStrength || "",
          packageSize: item.packageSize || "",
          numberOfQuantities: item.quantity || "",
          mrp: item.MRP || "",
          discountPrice: item.discountPrice || "",
          frequency: item.frequency || "weekly",
          recurring: item.recurring,
          notificationDate: item.notificationDate
            ? new Date(item.notificationDate)
            : null,
        })) || [],
    };

    setImageUrl(orderData.prescriptionDetails?.fileURL || null);
    setIsFromEdit(true);
    setFormData(formData); // Assuming you have a setFormData method
  };

  useEffect(() => {
    if (orderData && orderData.orderId) {
      setFormDataFromOrderData(orderData);
    }
  }, [orderData]);

  useEffect(() => {
    console.log("formdata is=====", formData);
  }, [formData]);

  const handleDeleteItem = (index) => {
    const updatedItems = formData.items.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      items: updatedItems,
    });
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "prescription" && files) {
      setFormData({ ...formData, prescription: files[0] });
    } else if (name === "totalAmount") {
      setFormData({ ...formData, totalAmount: parseFloat(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      user: { ...formData.user, [name]: value },
    });
  };

  const handleUpload = async (file, type) => {
    if (!file) return;

    try {
      //  set isuploading true
      setImageLoader(true);

      // check file size if it is less than 10MB
      // if (file.size < 10000000) {
      // Call your API to get the presigned URL
      const timestamp = new Date().getTime();

      const response = await axios.post(
        `https://api-staging.simpli5.in/upload/generatPresignUrl`,
        {
          fileName: `${type}/image/${timestamp}.png`,
        }
      );

      const url = response.data.presignedUrl;
      console.log("Response from server: ", url);

      // Use the presigned URL to upload the file
      const uploadResponse = await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      console.log("Final Uplaodresponse---------- ", uploadResponse);

      if (uploadResponse.status === 200) {
        // alert("File uploaded successfully.");
        let imageUrl = `${s5BucketBaseUrl}/${type}/image/${timestamp}.png`;
        // let imageUrl = `${s5BucketBaseUrl}/banner/image.png`;
        setFormData({
          ...formData,
          prescription: imageUrl,
        });
        setImageUrl(imageUrl);
        setImageLoader(false);
      } else {
        alert("Upload failed.");
      }

      // set isUpload false
      setImageLoader(false);
      // }
    } catch (error) {
      alert("Upload failed.");
    }
  };

  const handleImageUpload = (e) => {
    setImageLoader(true);
    setImageUrl(undefined);
    const files = e.target.files;

    if (files && files[0]) {
      handleUpload(files[0], "order");
    } else {
      setErrors({ image: "Please upload a valid JPG or PNG for Brand Image." });
    }
  };

  const handleMobileChange = async (e) => {
    const { name, value } = e.target;

    if (value.length == 10) {
      let result = await dispatch(isUserExistAction(value));
      let validationErrors = {};

      if (result) {
        setErrors((prevErrors) => {
          const { mobile, ...rest } = prevErrors; // Destructure to exclude 'mobile' from the new state
          return rest; // Return the rest of the errors
        });
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          mobile: "User does not exist",
        }));
      }
    }
    setFormData({
      ...formData,
      user: { ...formData.user, [name]: value },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoader(true);

    // Prepare data for submission to the API
    const apiData = {
      orderId: formData.orderId,
      chatId: formData.orderId, // Example chatId
      phoneNumber: formData.user.mobile,
      userName: formData.user.name,
      orderDate: formData.orderDate && convertToISOString(formData.orderDate),
      tata1MgOrderDate: formData.mgDate && convertToISOString(formData.mgDate),
      tata1MgOrderId: formData.id,
      addressDetails: {
        addressLine1: formData.user.addressLine1,
        addressLine2: formData.user.addressLine2,
        area: formData.user.area,
        country: formData.user.country,
        state: formData.user.state,
        city: formData.user.city,
        pincode: formData.user.pincode,
      },
      prescriptionDetails: {
        havePrescription: formData.prescription ? "yes" : "no",
        fileURL: formData.prescription ? formData.prescription : "",
      },
      orderItems: formData.items.map((item) => ({
        medicineName: item.medicine,
        medicineStrength: item.strength,
        packageSize: item.packageSize,
        quantity: item.numberOfQuantities,
        MRP: item.mrp,
        discountPrice: item.discountPrice,
        frequency: item.frequency,
        recurring: item.recurring,
        notificationDate: item.notificationDate,
      })),
      additionalInformation: {
        totalAmount: formData.totalAmount,
        couponCode: formData.couponcode,
        chatStartDateTime: formData.chatStartDateTime,
        chatEndDateTime: formData.chatEndDateTime,
        deliveryDate: formData.deliveryDate,
        orderStatus: formData.status ? formData.status : "",
        paymentStatus: formData.paymentStatus,
        paymentMode: formData.paymentMode,
        transactionNumber: formData.transactionNo,
      },
    };

    console.log("API Data:", apiData);
    if (isFromEdit == true) {
      let result = await dispatch(updateOrderAction(apiData));
      if (result) {
        navigate("/order");
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `${result}`,
          confirmButtonText: "OK",
        });
      }
    } else {
      let result = await dispatch(createOrderAction(apiData));
      if (result) {
        navigate("/order");
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `${result}`,
          confirmButtonText: "OK",
        });
      }
    }
    setLoader(false);

    // Assuming this will handle the API call
    // navigate("/order");
    // navigate("/order");
  };

  const handleCancel = () => {
    navigate("/order");
  };

  const handleDelete = () => {
    console.log("Order Deleted");
    navigate("/order");
  };

  const handleAddItem = () => {
    const newItem = {
      medicine: "",
      strength: "",
      packageSize: "",
      numberOfQuantities: "",
      mrp: "",

      discountPrice: "",
      frequency: "",
      recurrdingQty: "",
      notificationDate: null,
    };
    setFormData({ ...formData, items: [...formData.items, newItem] });
  };

  const validateForm = () => {
    let validationErrors = {};

    if (!formData.user.mobile.match(/^[0-9]{10}$/)) {
      validationErrors.mobile = "Phone number must be 10 digits.";
    }
    // if (!formData.user.pincode.match(/^[0-9]{6}$/)) {
    //   validationErrors.pincode = "Pincode must be 6 digits.";
    // }
    // if (!formData.totalAmount || formData.totalAmount <= 0) {
    //   validationErrors.totalAmount = "Total amount must be greater than zero.";
    // }
    // if (!formData.user.name) {
    //   validationErrors.name = "User name is required.";
    // }
    // if (!formData.orderDate) {
    //   validationErrors.orderDate = "Order date is required.";
    // }
    // if (!formData.id) {
    //   validationErrors.id = "Order ID is required.";
    // }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleItemChange = (index, key, value) => {
    const updatedItems = [...formData.items];
    updatedItems[index][key] =
      key === "notificationDate" ? new Date(value) : value;

    const numberOfQuantities =
      parseFloat(updatedItems[index].numberOfQuantities) || 0;
    const mrp = parseFloat(updatedItems[index].mrp) || 0;
    const discount = parseFloat(updatedItems[index].discount) || 0;
    const discountPrice =
      updatedItems[index].discountPrice === "yes" ? mrp * (discount / 100) : 0;

    updatedItems[index].value = numberOfQuantities * mrp - discountPrice;
    setFormData({ ...formData, items: updatedItems });

    // const total = updatedItems.reduce(
    //   (acc, item) => acc + (item.value || 0),
    //   0
    // );
    // setFormData((prevData) => ({ ...prevData, totalAmount: total }));
  };

  return (
    <div className="container-fluid">
      <PageTitle
        activeMenu="Add Order"
        motherMenu="Orders"
        pageContent="Order Information"
      />
      <div
        className="card"
        style={{ border: "1px solid #ddd", borderRadius: "8px" }}
      >
        <div className="card-body p-4" style={{ padding: "2rem" }}>
          <h4 className="card-title">Add Order</h4>
          <form onSubmit={handleSubmit}>
            {/* Order ID and Order Date */}
            <div className="row mb-4">
              <div className="col-xl-4">
                <h5>Tata 1mg:</h5>
                <input
                  type="text"
                  name="id"
                  value={formData.id}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter Order ID"
                />
                {errors.id && (
                  <label
                    style={{
                      color: "red",
                      fontSize: "0.875rem",
                      marginTop: "0.25rem",
                      display: "block",
                    }}
                    className="invalid-feedback"
                  >
                    {errors.id}
                  </label>
                )}
              </div>
              <div className="col-xl-4 mb-3">
                <label className="form-label">Order Date</label>
                <div style={{ position: "relative" }}>
                  <input
                    type="date"
                    name="orderDate"
                    value={
                      formData.orderDate
                        ? new Date(formData.orderDate)
                            .toISOString()
                            .slice(0, 10)
                        : ""
                    }
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        orderDate: new Date(e.target.value),
                      })
                    }
                    className="form-control"
                    style={{ paddingRight: "30px" }}
                  />
                  <i
                    className="fa fa-calendar"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                    }}
                  ></i>
                  {errors.orderDate && (
                    <label
                      style={{
                        color: "red",
                        fontSize: "0.875rem",
                        marginTop: "0.25rem",
                        display: "block",
                      }}
                      className="invalid-feedback"
                    >
                      {errors.orderDate}
                    </label>
                  )}
                </div>
              </div>
              <div className="col-xl-4 mb-3">
                <label className="form-label">1Mg Order Date</label>
                <div style={{ position: "relative" }}>
                  <input
                    type="date"
                    name="mgDate"
                    value={
                      formData.mgDate
                        ? new Date(formData.mgDate).toISOString().slice(0, 10)
                        : ""
                    }
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        mgDate: new Date(e.target.value),
                      })
                    }
                    className="form-control"
                    style={{ paddingRight: "30px" }}
                  />
                  <i
                    className="fa fa-calendar"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                    }}
                  ></i>
                </div>
              </div>
            </div>

            {/* User Information */}
            <div className="row mb-3">
              <div className="col-xl-4 mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.user.name}
                  onChange={handleUserChange}
                  className="form-control"
                  placeholder="Enter Name"
                />
                {errors.name && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "0.875rem",
                      marginTop: "0.25rem",
                      display: "block",
                    }}
                    className="invalid-feedback"
                  >
                    {errors.name}
                  </div>
                )}
              </div>
              <div className="col-xl-4 mb-3">
                <label className="form-label">Mobile Number</label>
                <input
                  type="number"
                  name="mobile"
                  value={formData.user.mobile}
                  onChange={handleMobileChange}
                  className="form-control"
                  placeholder="Enter Mobile Number"
                  maxLength={10}
                />
                {errors.mobile && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "0.875rem",
                      marginTop: "0.25rem",
                      display: "block",
                    }}
                    className="invalid-feedback"
                  >
                    {errors.mobile}
                  </div>
                )}
              </div>
              {/* Address Line 1 */}
              <div className="col-xl-4 mb-3">
                <label className="form-label">Address Line 1</label>
                <input
                  type="text"
                  name="addressLine1"
                  value={formData.user.addressLine1 || ""}
                  onChange={handleUserChange}
                  className="form-control"
                  placeholder="Enter Address Line 1"
                />
              </div>

              {/* Address Line 2 */}
              <div className="col-xl-4 mb-3">
                <label className="form-label">Address Line 2</label>
                <input
                  type="text"
                  name="addressLine2"
                  value={formData.user.addressLine2 || ""}
                  onChange={handleUserChange}
                  className="form-control"
                  placeholder="Enter Address Line 2"
                />
              </div>

              {/* Address Line 3 */}
              <div className="col-xl-4 mb-3">
                <label className="form-label">Area</label>
                <input
                  type="text"
                  name="area"
                  value={formData.user.area || ""}
                  onChange={handleUserChange}
                  className="form-control"
                  placeholder="Enter Area"
                />
              </div>
              <div className="col-xl-4 mb-3">
                <label className="form-label">Country</label>
                <input
                  type="text"
                  name="country"
                  value={formData.user.country}
                  onChange={handleUserChange}
                  className="form-control"
                  placeholder="Enter Country"
                />
              </div>
              <div className="col-xl-4 mb-3">
                <label className="form-label">State</label>
                <input
                  type="text"
                  name="state"
                  value={formData.user.state}
                  onChange={handleUserChange}
                  className="form-control"
                  placeholder="Enter State"
                />
              </div>
              <div className="col-xl-4 mb-3">
                <label className="form-label">City</label>
                <input
                  type="text"
                  name="city"
                  value={formData.user.city}
                  onChange={handleUserChange}
                  className="form-control"
                  placeholder="Enter City"
                />
              </div>

              <div className="col-xl-4 mb-3">
                <label className="form-label">Pincode</label>
                <input
                  // type="text"
                  name="pincode"
                  value={formData.user.pincode || ""}
                  onChange={handleUserChange}
                  className="form-control"
                  placeholder="Enter Pincode"
                  pattern="[0-9]{6}"
                  maxLength="6"
                  inputMode="numeric" // Opens numeric keypad on mobile devices
                />
                {errors.pincode && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "0.875rem",
                      marginTop: "0.25rem",
                      display: "block",
                    }}
                    className="invalid-feedback"
                  >
                    {errors.pincode}
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-xl-4 mb-3">
                <label className="form-label">
                  Do you have a prescription? (accepts PNG, JPG, PDF)
                </label>
                <div style={{ position: "relative" }}>
                  <select
                    className="form-control"
                    name="hasPrescription"
                    value={formData.hasPrescription ? "yes" : "no"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        hasPrescription: e.target.value === "yes",
                      })
                    }
                    style={{ paddingRight: "30px" }}
                  >
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  <i
                    className="fa fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                    }}
                  ></i>
                </div>
              </div>
            </div>

            {/* Conditionally render the file input if "Yes" is selected */}
            {formData.hasPrescription && (
              <div className="row mb-4">
                <div className="col-xl-12">
                  <label className="form-label">
                    Upload Prescription (accepts PNG, JPG, PDF)
                  </label>
                  <input
                    type="file"
                    name="prescription"
                    accept=".png,.jpg,.jpeg,.pdf"
                    onChange={handleImageUpload}
                    className="form-control"
                  />

                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt={"Prescription"}
                      style={{
                        height: "200px",
                        aspectRatio: "1:1",
                        // objectFit: "fit",
                      }}
                    />
                  )}
                </div>
              </div>
            )}

            <h6 className="mt-4">Items</h6>
            {formData.items.map((item, index) => (
              <div key={index} className="card mb-3">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h5 className="card-title">Medicine {index + 1}</h5>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Delete"
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure you want to delete Item ${index + 1}?`
                          )
                        ) {
                          handleDeleteItem(index);
                        }
                      }}
                    ></button>
                  </div>
                  <div className="row align-items-end">
                    <div className="col mb-3">
                      <label className="form-label">Medicine Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Medicine Name"
                        value={item.medicine}
                        onChange={(e) =>
                          handleItemChange(index, "medicine", e.target.value)
                        }
                      />
                    </div>

                    <div className="col mb-3">
                      <label className="form-label">Medicine Strength</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Strength"
                        value={item.strength}
                        onChange={(e) =>
                          handleItemChange(index, "strength", e.target.value)
                        }
                      />
                    </div>

                    <div className="col mb-3">
                      <label className="form-label">Package Size</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Package Size (e.g., 500mg, 2ml)"
                        value={item.packageSize}
                        onChange={(e) =>
                          handleItemChange(index, "packageSize", e.target.value)
                        }
                      />
                    </div>

                    <div className="col mb-3">
                      <label className="form-label">Quantity</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Qty"
                        value={item.numberOfQuantities}
                        onChange={(e) =>
                          handleItemChange(
                            index,
                            "numberOfQuantities",
                            parseInt(e.target.value) || 0
                          )
                        }
                        min="1"
                      />
                    </div>

                    <div className="col mb-3">
                      <label className="form-label">MRP</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="MRP"
                        value={item.mrp}
                        onChange={(e) =>
                          handleItemChange(
                            index,
                            "mrp",
                            parseFloat(e.target.value) || 0
                          )
                        }
                        min="0"
                        step="0.01"
                      />
                    </div>

                    <div className="col mb-3">
                      <label className="form-label">Discount price</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter discount price"
                        value={item.discountPrice}
                        onChange={(e) =>
                          handleItemChange(
                            index,
                            "discountPrice",
                            Number(e.target.value) || 0
                          )
                        }
                        min="0"
                      />
                    </div>

                    <div className="col mb-3">
                      <label className="form-label">Frequency</label>
                      <div style={{ position: "relative" }}>
                        <select
                          className="form-control"
                          value={item.frequency}
                          onChange={(e) =>
                            handleItemChange(index, "frequency", e.target.value)
                          }
                        >
                          <option value="select">Select</option>
                          <option value="weekly">Weekly</option>
                          <option value="bimonthly">Bi-Monthly</option>
                          <option value="monthly">Monthly</option>
                        </select>
                        <i
                          className="fa fa-caret-down"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            pointerEvents: "none",
                          }}
                        ></i>
                      </div>
                    </div>

                    <div className="col mb-3">
                      <label className="form-label">Recurring</label>
                      <div style={{ position: "relative" }}>
                        <select
                          className="form-control"
                          value={item.recurring}
                          onChange={(e) =>
                            handleItemChange(index, "recurring", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                        <i
                          className="fa fa-caret-down"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            pointerEvents: "none",
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className="col mb-3">
                      <label className="form-label">Notification Date</label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="date"
                          className="form-control"
                          value={
                            item.notificationDate
                              ? item.notificationDate
                                  .toISOString()
                                  .split("T")[0]
                              : ""
                          }
                          onChange={(e) =>
                            handleItemChange(
                              index,
                              "notificationDate",
                              e.target.value ? new Date(e.target.value) : null
                            )
                          }
                          style={{ paddingRight: "30px" }}
                        />
                        <i
                          className="fa fa-calendar"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            pointerEvents: "none",
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <button
              type="button"
              className="btn btn-primary mb-4"
              onClick={handleAddItem}
            >
              Add Item
            </button>

            {/* Additional Information */}
            <h6 className="mt-4">Additional Information</h6>
            <div className="row mb-3">
              <div className="col-xl-4 mb-3">
                <label className="form-label">Total Amount</label>
                <input
                  type="number"
                  name="totalAmount"
                  value={formData.totalAmount}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter Total Amount"
                />
                {errors.totalAmount && (
                  <label
                    style={{
                      color: "red",
                      fontSize: "0.875rem",
                      marginTop: "0.25rem",
                      display: "block",
                    }}
                    className="invalid-feedback"
                  >
                    {errors.totalAmount}
                  </label>
                )}
              </div>
              <div className="col-xl-4 mb-3">
                <label className="form-label">Coupon Code</label>
                <input
                  type="text"
                  name="couponcode"
                  value={formData.couponcode}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter Coupon Code"
                />
              </div>
              <div className="col-xl-4 mb-3">
                <label className="form-label">Chat Start Date and Time</label>
                <input
                  type="datetime-local"
                  name="chatStartDateTime"
                  value={
                    formData.chatStartDateTime
                      ? formatDateTimeLocal(formData.chatStartDateTime)
                      : ""
                  }
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      chatStartDateTime: e.target.value
                        ? new Date(e.target.value)
                        : null,
                    })
                  }
                  className="form-control"
                />
              </div>
              <div className="col-xl-4 mb-3">
                <label className="form-label">Chat End Date and Time</label>

                <input
                  type="datetime-local"
                  name="chatEndDateTime"
                  value={
                    formData.chatEndDateTime
                      ? formatDateTimeLocal(formData.chatEndDateTime)
                      : ""
                  }
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      chatEndDateTime: e.target.value
                        ? new Date(e.target.value)
                        : null,
                    })
                  }
                  className="form-control"
                />
              </div>
              <div className="col-xl-4 mb-3">
                <label className="form-label">Delivery Date</label>
                <div style={{ position: "relative" }}>
                  <input
                    type="date"
                    name="deliveryDate"
                    value={
                      formData.deliveryDate
                        ? formData.deliveryDate.toISOString().split("T")[0]
                        : ""
                    }
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        deliveryDate: e.target.value
                          ? new Date(e.target.value)
                          : null,
                      })
                    }
                    className="form-control"
                    style={{ paddingRight: "30px" }}
                  />
                  <i
                    className="fa fa-calendar"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                    }}
                  ></i>
                </div>
              </div>

              <div className="col-xl-4 mb-3">
                <label className="form-label">Order Status</label>
                <div style={{ position: "relative" }}>
                  <select
                    name="status"
                    value={formData.status}
                    onChange={handleInputChange}
                    className="form-control"
                    style={{ paddingRight: "30px" }}
                  >
                    <option value="">Select Order Status</option>
                    <option value="New">{OrderStatus.New}</option>
                    <option value="InProgress">{OrderStatus.InProgress}</option>
                    <option value="Delivered">{OrderStatus.Delivered}</option>
                    <option value="Cancelled">{OrderStatus.Cancelled}</option>
                  </select>
                  <i
                    className="fa fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-xl-4 mb-3">
                <label className="form-label">Payment Status</label>
                <div style={{ position: "relative" }}>
                  <select
                    name="paymentStatus"
                    value={formData.paymentStatus}
                    onChange={handleInputChange}
                    className="form-control"
                    style={{ paddingRight: "30px" }}
                  >
                    <option value="">Select Payment Status</option>
                    <option value="pending">{PaymentStatus.Pending}</option>
                    <option value="completed">{PaymentStatus.Paid}</option>
                  </select>
                  <i
                    className="fa fa-caret-down"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                    }}
                  ></i>
                </div>
              </div>

              <div className="col-xl-4 mb-3">
                <label className="form-label">Payment Mode</label>
                <input
                  type="text"
                  name="paymentMode"
                  value={formData.paymentMode}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter Payment Mode"
                />
              </div>

              <div className="col-xl-4 mb-3">
                <label className="form-label">Transaction Number</label>
                <input
                  type="text"
                  name="transactionNo"
                  value={formData.transactionNo}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter Transaction No"
                />
              </div>
            </div>

            <div className="mt-4">
              <button type="submit" className="btn btn-primary me-2">
                {loader ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Submit"
                )}
              </button>
              <button
                type="button"
                className="btn btn-danger me-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
