import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import BannerOffcanvas from "../../constant/Banneroffcanvas";
import MainPagetitle from "../../layouts/MainPagetitle";

// Import avatar images
import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";
import { connect, useDispatch } from "react-redux";
import {
  createBannerAction,
  deleteBannerAction,
  getBannersAction,
} from "../../../store/actions/BannerActions";
import Loader from "../Loader/Loader";
import { NUMBER_OF_ITEMS_PER_PAGE } from "../../../constants";
import Swal from "sweetalert2";

const Banners = (props) => {
  const [tableData, setTableData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = NUMBER_OF_ITEMS_PER_PAGE;
  const bannerRef = useRef(null);
  const dispatch = useDispatch();
  const [selectedBanner, setSelectedBanner] = useState(undefined);
  const [loader, setLoader] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    async function getData() {
      // dispatch(loadingToggleAction(true));
      setLoader(true);
      let result = await dispatch(getBannersAction());
      setLoader(false);
    }
    getData();
  }, []);

  const refreshBannerList = () => {
    setLoader(false);
    dispatch(getBannersAction()); // Fetch the updated banner list after add/update
  };
  const startLoader = () => {
    setLoader(true);
  };

  useEffect(() => {
    if (props.bannerData && props.bannerData.length > 0) {
      setTableData(props.bannerData);
      setLoader(false);
    }
  }, [props.bannerData]);

  useEffect(() => {
    if (selectedBanner) {
      bannerRef.current.showEmployeModal();
    }
  }, [selectedBanner]);

  const handleDelete = async (index) => {
    let result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      const actualIndex = (activePage - 1) * itemsPerPage + index;
      const tobeDeleted = tableData[actualIndex];
      tobeDeleted.deleted = true;
      let result = await dispatch(
        deleteBannerAction(tobeDeleted, tobeDeleted._id)
      );

      await refreshBannerList();
      // Swal.fire("Canceled!", "Your order has been canceled.", "success");
      Swal.fire({
        icon: "success",
        title: "Deleted!",
        text: `${result}`,
        confirmButtonText: "OK",
      });
    }
  };

  const handleEdit = (index) => {
    console.log("Edit banner at index:", index);
    const actualIndex = (activePage - 1) * itemsPerPage + index;

    // navigate("/add");
    setSelectedBanner(tableData[actualIndex]);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    if (loader) {
      document.body.classList.add("blur");
    } else {
      document.body.classList.remove("blur");
    }
  }, [loader]);

  useEffect(() => {
    if (tableData.length > 0) {
      const startIndex = (activePage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const currentPageData = tableData.slice(startIndex, endIndex);
      setPaginatedData(currentPageData);
    }
    // Set data for the current page
  }, [activePage, tableData]);

  return (
    <>
      {loader && <Loader />}

      <MainPagetitle
        mainTitle="Banners"
        pageTitle="Banners"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Banners</h4>
                    <div className="d-flex align-items-center">
                      <Link
                        to="#"
                        className="btn btn-primary btn-sm ms-1"
                        onClick={() => {
                          setSelectedBanner(undefined);
                          setTimeout(() => {
                            bannerRef.current.showEmployeModal();
                          }, 500);
                        }}
                      >
                        + Add Banner
                      </Link>
                    </div>
                  </div>

                  <div
                    id="Banners-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Image</th>
                          <th>Status</th>
                          {/* <th>Date</th> */}
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedData &&
                          paginatedData.length > 0 &&
                          paginatedData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.title}</td>
                              <td>
                                <img
                                  src={item.imageUrl}
                                  alt={item.title}
                                  style={{
                                    height: "200px",
                                    aspectRatio: "16/9",
                                    // objectFit: "fit",
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  className={`badge light border-0 ${
                                    item.status === true
                                      ? "badge-success"
                                      : "badge-danger"
                                  }`}
                                >
                                  {item.status === true ? "Active" : "Inactive"}
                                </span>
                              </td>
                              {/* <td>{item?.createdAt?.toString()}</td> */}
                              <td>
                                <Link
                                  to="#"
                                  className="btn btn-primary btn-sm me-2"
                                  onClick={() => handleEdit(index)}
                                >
                                  <i className="fa-solid fa-pencil-alt" />
                                </Link>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => handleDelete(index)}
                                >
                                  <i className="fa-solid fa-trash" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        <>
                          {paginatedData.length == 0 && (
                            <tr>
                              <td colSpan="4" style={{ textAlign: "center" }}>
                                Banners not found.
                              </td>
                            </tr>
                          )}
                        </>
                      </tbody>
                    </table>
                  </div>

                  {/* Pagination Controls */}
                  <div
                    className="pagination-controls d-flex justify-content-between"
                    style={{ padding: "15px", textAlign: "right" }}
                  >
                    <ul
                      className="pagination"
                      style={{
                        display: "inline-flex",
                        listStyle: "none",
                        padding: 0,
                      }}
                    >
                      {Array.from({
                        length: Math.ceil(tableData.length / itemsPerPage),
                      }).map((_, i) => (
                        <li
                          key={i + 1}
                          className={`page-item ${
                            activePage === i + 1 ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(i + 1)}
                            style={{
                              border: "1px solid #ddd",
                              padding: "5px 10px",
                              cursor: "pointer",
                            }}
                          >
                            {i + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BannerOffcanvas
        onStartLoader={startLoader}
        ref={bannerRef}
        Title={selectedBanner ? "Edit Banner" : "Add Banner"}
        selectedBanner={selectedBanner}
        onSuccess={refreshBannerList} // This will trigger the banner list refresh
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.banners.errorMessage,
    successMessage: state.banners.successMessage,
    bannerData: state.banners.banners,
    showLoading: state.banners.showLoading,
  };
};
export default connect(mapStateToProps)(Banners);
