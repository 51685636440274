export const CREATE_ROLE_ACTION = "[ROLE Action] Create ROLE";
export const CONFIRMED_CREATE_ROLE_ACTION =
  "[ROLE Action] Confirmed Create ROLE";
export const GET_ROLES = "[ROLE Action] Get ROLEs";
export const CONFIRMED_GET_ROLES = "[ROLE Action] Confirmed Get ROLEs";
export const EDIT_ROLE_ACTION = "[ROLE Action] Edit ROLE";
export const CONFIRMED_EDIT_ROLE_ACTION =
  "[ROLE Action] Confirmed Edit ROLE";
export const CONFIRMED_DELETE_ROLE_ACTION =
  "[ROLE Action] Confirmed Delete ROLE";
