import axiosInstance from "./AxiosInstance";

export function getAppUsers() {
  let data = {
    // title: "paul walker new",
    // imageUrl:
    //   "https://www.nme.com/news/film/paul-walkers-fast-furious-car-sells-for-555000-at-auction-2975453.jpg",
    // status: "view",
    // deleted: 0,
  };
  return axiosInstance.get(`/admin/user-manage/getUserData`, data);
}

export function createAppUser(appuserData) {
  return axiosInstance.post(`/admin/user-manage`, appuserData);
}

export function updateAppUser(appuserData, appuserId) {
  return axiosInstance.put(`/admin/user-manage/${appuserId}`, appuserData);
}

export function deleteAppUser(appuser) {
  return axiosInstance.put(`/admin/user-manage/${appuser._id}`, appuser);
}

export function formatAppUsers(appuserData) {
  let appusers = [];
  for (let key in appuserData) {
    appusers.push({ ...appuserData[key], id: key });
  }

  return appusers;
}
