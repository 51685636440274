import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainPagetitle from "../../layouts/MainPagetitle";
import { connect, useDispatch } from "react-redux";
import { getAppUsersAction } from "../../../store/actions/AppUserActions";
import { NUMBER_OF_ITEMS_PER_PAGE } from "../../../constants";

const AppUser = (props) => {
  const itemsPerPage = NUMBER_OF_ITEMS_PER_PAGE;
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData =
    filteredData.length > 0
      ? filteredData.slice(startIndex, endIndex)
      : tableData.slice(startIndex, endIndex);
  const dispatch = useDispatch();

  const [loader, setLoader] = useState();

  useEffect(() => {
    if (loader) {
      document.body.classList.add("blur");
    } else {
      document.body.classList.remove("blur");
    }
  }, [loader]);

  useEffect(() => {
    async function getData() {
      dispatch(getAppUsersAction());
    }
    getData();
  }, []);

  useEffect(() => {
    if (props.appusers && props.appusers.length > 0) {
      setTableData(props.appusers);
      setFilteredData(props.appusers);
    } else {
      setTableData([]); // If there's no data, set an empty array
      setFilteredData([]); // If there's no data, set an empty array
    }
  }, [props.appusers]);

  const ProgressBar = ({ value }) => (
    <div style={{ display: " flex", alignItems: "center" }}>
      <div
        style={{
          flexGrow: 1,
          backgroundColor: "#f0f0f0",
          borderRadius: "4px",
          height: "5px",
          marginRight: "10px",
        }}
      >
        <div
          style={{
            width: value,
            height: "5px",
            borderRadius: "4px",
            backgroundColor: "#007bff",
          }}
        ></div>
      </div>
      <span style={{ color: "#007bff" }}>{value}</span>
    </div>
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredData = props.appusers.filter((user) => {
      return (
        (user.name && user.name.toLowerCase().includes(searchTerm)) ||
        (user.displayName &&
          user.displayName.toLowerCase().includes(searchTerm)) ||
        (user.userPhonePrimary &&
          user.userPhonePrimary.toLowerCase().includes(searchTerm)) ||
        (user.email && user.email.toLowerCase().includes(searchTerm)) ||
        (user.profileCompletion &&
          user.profileCompletion.toString().includes(searchTerm))
      );
    });
    setFilteredData(filteredData);
    setSearchTerm(searchTerm);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
    const sortedData = [...filteredData].sort((a, b) => {
      if (column === "name") {
        return sortDirection === "asc"
          ? a.userName.localeCompare(b.userName)
          : b.userName.localeCompare(a.userName);
      } else if (column === "displayName") {
        return sortDirection === "asc"
          ? a.displayName.localeCompare(b.displayName)
          : b.displayName.localeCompare(a.displayName);
      } else if (column === "userPhonePrimary") {
        return sortDirection === "asc"
          ? a.userPhonePrimary.localeCompare(b.userPhonePrimary)
          : b.userPhonePrimary.localeCompare(a.userPhonePrimary);
      } else if (column === "email") {
        return sortDirection === "asc"
          ? a.email.localeCompare(b.email)
          : b.email.localeCompare(a.email);
      } else if (column === "profileCompletion") {
        return sortDirection === "asc"
          ? a.profileCompletion - b.profileCompletion
          : b.profileCompletion - a.profileCompletion;
      } else {
        return 0;
      }
    });
    setFilteredData(sortedData);
  };

  return (
    <>
      <MainPagetitle
        mainTitle="App Users"
        pageTitle="App Users"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">App Users</h4>
                    <div className="d-flex align-items-center">
                      <input
                        type="search"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="form-control"
                        style={{ width: "300px" }}
                      />
                    </div>
                  </div>
                  <div
                    id="AppUsers-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
                      <thead>
                        <tr>
                          <th onClick={() => handleSort("name")}>
                            Name
                            {sortColumn === "name" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th onClick={() => handleSort("displayName")}>
                            Display Name
                            {sortColumn === "displayName" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th onClick={() => handleSort("userPhonePrimary")}>
                            Mobile Number
                            {sortColumn === "userPhonePrimary" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th onClick={() => handleSort("email")}>
                            Email
                            {sortColumn === "email" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th onClick={() => handleSort("profileCompletion")}>
                            Profile
                            {sortColumn === "profileCompletion" ? (
                              sortDirection === "asc" ? (
                                <i className="fa-solid fa-sort-up mx-2" />
                              ) : (
                                <i className="fa-solid fa-sort-down mx-2" />
                              )
                            ) : (
                              <i className="fa-solid fa-sort mx-2" />
                            )}
                          </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.userName}</td>
                            <td>{item.displayName}</td>
                            <td>{item.userPhonePrimary}</td>
                            <td>{item.email}</td>
                            <td className="pe-0">
                              <ProgressBar
                                value={`${
                                  item.profileCompletion
                                    ? item.profileCompletion
                                    : "100"
                                }%`}
                              />
                            </td>
                            <td>
                              <Link
                                to="/userdetail"
                                state={{ userData: item }}
                                className="btn btn-primary btn-sm"
                              >
                                <i className="fa fa-eye" />
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* Pagination Controls */}
                  <div
                    className="pagination-controls"
                    style={{ padding: "15px", textAlign: "right" }}
                  >
                    <ul
                      className="pagination"
                      style={{
                        display: "inline-flex",
                        listStyle: "none",
                        padding: 0,
                      }}
                    >
                      {Array.from({
                        length: Math.ceil(filteredData.length / itemsPerPage),
                      }).map((_, i) => (
                        <li
                          key={i + 1}
                          className={`page-item ${
                            currentPage === i + 1 ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(i + 1)}
                            style={{
                              border: "1px solid #ddd",
                              padding: "5px 10px",
                              cursor: "pointer",
                            }}
                          >
                            {i + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appusers: state.appusers.appusers || [], // Ensure it's an array even if undefined
    // errorMessage: state.appuser.errorMessage,
    // successMessage: state.appuser.successMessage,
    // showLoading: state.appuser.showLoading,
  };
};
export default connect(mapStateToProps)(AppUser);
